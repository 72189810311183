import React from "react";
import { Row, Image, Button, Col } from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";

import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";

import Navbar from "../../../common/Navbar";
import bannerVideo from "../../../assets/images/Homepage.webm";
import { Colors } from "../../../common/theme/theme";

import { SocialComponent } from "../../../common/GlobalStyles/global";
import { FixedComponent } from "../../../common/GlobalStyles/global";
import { StyledIcon } from "../../../common/GlobalStyles/global";


const Video = styled.video`
  width: 100vw;
  object-fit: cover;
  height: 90vh;

  @media screen and (max-width: 767px) {
    padding-top: 56.25%; /* 16:9 aspect ratio for mobile devices */
    height: 60vh;
    object-fit: fill;
  }

  @media screen and (min-width: 768px) and (max-width: 1199px) {
    padding-top: 50%; /* Adjust the aspect ratio for tablets */
    height: 70vh;
    object-fit: fill;
  }

  @media screen and (min-width: 1200px) {
    padding-top: 40%; /* Adjust the aspect ratio for desktops */
  }
`;


const MWButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #01497c;
  color: white;

  font-size: 16px;
  text-align: center;
  font-weight: bold;
  opacity: 1;
  border: 0;
  text-decoration: none;
  padding-inline: 4rem;
  height: 60px;
  border-radius: 60px;

  margin-top: 3rem;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
    color: white !important;
    text-decoration: none;
  }
`;

const BoldHeading = styled.div`
  text-align: center;
  font-size: 8rem;
  font-weight: 600;
  color: white;

  font-family: "Teko", sans-serif;
  @media (max-width: 768px) {
    font-size: 6rem;
  }
`;
const BoldSubHeading = styled.div`
  text-align: center;
  font-family: "Teko", sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
  color: white;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 430px) {
    font-size: 1.75rem;
  }
`;



const Heading = styled.h1`
@media (max-width: 425px){
  font-size: 20px !important;
  margin-top: 4rem;
}
`;

const Para = styled.p`
@media (max-width: 425px){
  font-size: 15px !important;
}
`;



const Slide = ({ key, src, caption, desc }) => {
  return (
    <div style={{ position: "relative" }} key={key}>
      <Image
        src={src}
        width={"90vw"}
        height={"100vh"}
        preview={false}
        style={{ filter: "brightness(.4)", objectfit: "contain" }}
      />

      <div className="slide-content">
        <BoldHeading>{caption}</BoldHeading>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "150px",
              border: "2px solid #01497C",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          ></div>
        </div>
        <BoldSubHeading>{desc}</BoldSubHeading>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <MWButton>More Information</MWButton>
        </div>
      </div>
    </div>
  );
};

const Carousel = () => {
  const settings = {
    dots: true,
    dotsClass: "slick-dots custom-dots",
    customPaging: function (i) {
      return <div className="dot" />;
    },
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: null,
  };


  return (
    <>
      <FixedComponent>
        <Navbar temp={true} />
      </FixedComponent>
      <Row>
        <Col>
          <Video
            autoPlay
            muted
            loop
            style={{
              marginTop: "90px",
              width: "100%",
              margin: 0,
              padding: 0,
            }}
          >
            <source
              src={bannerVideo}
              type="video/mp4"
              style={{ margin: 0, padding: 0 }}
            />
            Your browser does not support the video tag.
          </Video>

          <SocialComponent>
            <StyledIcon>
              <a
                href="https://www.facebook.com/Macworldtechnology"
                target="_blank"
              >
                <FaFacebookF />
              </a>
            </StyledIcon>
            <StyledIcon>
              <a
                href="https://www.instagram.com/macworldtechnology/"
                target="_blank"
              >
                <FaInstagram />
              </a>
            </StyledIcon>
            <StyledIcon>
              <a
                href="https://www.linkedin.com/company/mac-world-technology"
                target="_blank"
              >
                <FaLinkedinIn />
              </a>
            </StyledIcon>
            <StyledIcon>
              <FaTwitter />
            </StyledIcon>

            <StyledIcon>
              <FaYoutube />
            </StyledIcon>
          </SocialComponent>
        </Col>
      </Row>
    </>
  );
};

export default Carousel;
