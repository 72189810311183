import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ParallaxProvider } from 'react-scroll-parallax';
import AppContainer from './common/AppContainer';
ReactDOM.render(
  <React.StrictMode>
    {/* <AppContainer> */}
   <ParallaxProvider>
     <App/>
    </ParallaxProvider>
    {/* </AppContainer> */}
  </React.StrictMode>,
  document.getElementById('root')
);
