import React from "react";

import Office1 from "../../../assets/images/offffice1.jpg";

import BannerComponent from "../../../common/BannerComponent";

const Carousel = () => {
 

  return (
    <BannerComponent
    title={"Contact Us | Reach Out For Customer Support    "}
    desc={
      "Need assistance? Contact Mac World Technology for reliable customer support. Find our contact information.      "
    }
    imagepath={Office1}
  />

  );
};

export default Carousel;
