import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { Suspense, lazy, Image } from "react";
import ScrollToTop from "./common/ScrollToTop/ScrollToTop";
import loading from "../src/assets/images/Loading screen/loading.gif";

import "./App.css";

// Form
import Form from "./pages/Opening/Form";
//Front panel
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Footer from "./common/Footer";
import Careers from "./pages/Careers";

// it support
//web development
const WebDevelopment = lazy(() =>
  import("./pages/ITSupport/WebDevelopment/WebsiteDevelopment")
);
const Webdesigning = lazy(() =>
  import("./pages/ITSupport/WebDevelopment/WebDesigning")
);
const Contentmangementsystem = lazy(() =>
  import("./pages/ITSupport/WebDevelopment/ContentManagementSystem")
);
const Html5development = lazy(() =>
  import("./pages/ITSupport/WebDevelopment/Html5WebDevelopment")
);
const Micrositesandlanding = lazy(() =>
  import("./pages/ITSupport/WebDevelopment/MicrositesAndLandingPages")
);
const Wordpressdevelopment = lazy(() =>
  import("./pages/ITSupport/WebDevelopment/WordPressDevelopment")
);

// ecommence

const ECommerceDevelopment = lazy(() =>
  import("./pages/ITSupport/Ecommerce/eCommerceDevelopment")
);
const Magento = lazy(() => import("./pages/ITSupport/Ecommerce/Magento"));
const Shopify = lazy(() => import("./pages/ITSupport/Ecommerce/shopify"));
const WooCommerce = lazy(() =>
  import("./pages/ITSupport/Ecommerce/WooCommerce")
);

//application development
const CustomerSoftwareDevelopment = lazy(() =>
  import("./pages/ITSupport/ApplicationDevelopment/CustomerSoftwareDevelopment")
);
const SoftwareDevelopment = lazy(() =>
  import("./pages/ITSupport/ApplicationDevelopment/ApplicationDevelopment")
);
const OpenSourceDevelopment = lazy(() =>
  import("./pages/ITSupport/ApplicationDevelopment/OpenSourceDevelopment")
);

// mobile application

const AndroidDevelopment = lazy(() =>
  import("./pages/ITSupport/MobileDevelopment/AndroidDevelopment")
);
const MobileDevelopment = lazy(() =>
  import("./pages/ITSupport/MobileDevelopment/MobileDevelopment")
);
const IOSDevelopment = lazy(() =>
  import("./pages/ITSupport/MobileDevelopment/IosDevelopment")
);

//hosted applicaiton
const HostedWebDevelopment = lazy(() =>
  import("./pages/ITSupport/HostedApplication/HostedWebDevelopment")
);
const Hostedapplicationdevelopment = lazy(() =>
  import("./pages/ITSupport/HostedApplication/HostedApplicationDevelopment")
);

// Web Maintenenance Service
const WebServiceAMC = lazy(() => import("./pages/ITSupport/WebService&AMC"));
const WebsiteMaintenanceServices = lazy(() =>
  import("./pages/ITSupport/WebService&AMC/WebsiteMaintenanceService")
);

//media service
//graphics
const CorporateBranding = lazy(() =>
  import("./pages/MediaService/Graphics/CorporateBranding")
);
const CreativeLogoCreation = lazy(() =>
  import("./pages/MediaService/Graphics/CreativeLogoCreation")
);
const ProductBranding = lazy(() =>
  import("./pages/MediaService/Graphics/ProductBranding")
);
const FlyerDesigning = lazy(() =>
  import("./pages/MediaService/Graphics/FlyerDesigning")
);
const BrochureDesigning = lazy(() =>
  import("./pages/MediaService/Graphics/BrochureDesign")
);
const BusinessCardDesigning = lazy(() =>
  import("./pages/MediaService/Graphics/BusinessCardDesigning")
);
const GraphicDesigning = lazy(() =>
  import("./pages/MediaService/Graphics/GraphicDesign")
);

//music and audio

//video and animation
const EditingandPostProduction = lazy(() =>
  import("./pages/MediaService/VideoandAnimation/EditingandPostProduction")
);
const PromotionalVideo = lazy(() =>
  import("./pages/MediaService/VideoandAnimation/PromotionalVideo")
);
const ShortVideoAds = lazy(() =>
  import("./pages/MediaService/VideoandAnimation/ShortVideoAds")
);
const SpokespersonVideo = lazy(() =>
  import("./pages/MediaService/VideoandAnimation/SpokespersonVideo")
);
const VideoandAnimation = lazy(() =>
  import("./pages/MediaService/VideoandAnimation/VideoandAnimation")
);

// digtal marketing
const BlogCreationAndPromotion = lazy(() =>
  import("./pages/Marketing/DigitalMarketing/BlogCreationAndPromotion")
);
const DigitalMarketing = lazy(() =>
  import("./pages/Marketing/DigitalMarketing/DigitalMarketing")
);
const PayPerClickMarketing = lazy(() =>
  import("./pages/Marketing/DigitalMarketing/PayPerClickMarketing")
);
const SearchEngineOptimization = lazy(() =>
  import("./pages/Marketing/DigitalMarketing/SearchEngineOptimization")
);
const SocialMediaMarketing = lazy(() =>
  import("./pages/Marketing/DigitalMarketing/SocialMediaMarketing")
);
const SocialMediaOptimization = lazy(() =>
  import("./pages/Marketing/DigitalMarketing/SocialMediaOptimization")
);
const UiUx = lazy(() => import("./pages/Marketing/DigitalMarketing/UiUx"));
const DevOps = lazy(() => import("./pages/Marketing/DigitalMarketing/DevOps"));
const ContractMarketing = lazy(() =>
  import("./pages/Marketing/ContractMarketing/ContractMarketing")
);

//email marketing
const EmailMarketing = lazy(() =>
  import("./pages/Marketing/EmailMarketing/EmailMarketingService")
);
// Advertising services
const GoogleAdWordsAndBingAds = lazy(() =>
  import("./pages/Marketing/AdvertisingServices/GoogleAdWordsAndBingAds")
);
const GoogleMyBusiness = lazy(() =>
  import("./pages/Marketing/AdvertisingServices/GooglePlacesOptimiztion")
);
const AdvertisingService = lazy(() =>
  import("./pages/Marketing/AdvertisingServices/AdvertisingService")
);

//Writing
//legal technical
const BusinessCopywriting = lazy(() =>
  import("./pages/Writing/LegalandTechnical/BusinessCopywriting")
);
const ITLegalWriting = lazy(() =>
  import("./pages/Writing/LegalandTechnical/ITLegalWriting")
);
const Transcription = lazy(() =>
  import("./pages/Writing/LegalandTechnical/Transcription")
);

//professional content writing
const ArticlesWebandBlogPosts = lazy(() =>
  import("./pages/Writing/ProfessionalContentWriting/ArticlesWebandBlogPosts")
);
const CreativeWriting = lazy(() =>
  import("./pages/Writing/ProfessionalContentWriting/CreativeWriting")
);
const ProfessionalContentWriting = lazy(() =>
  import(
    "./pages/Writing/ProfessionalContentWriting/ProfessionalContentWriting"
  )
);
const SEObasedContentWriting = lazy(() =>
  import("./pages/Writing/ProfessionalContentWriting/SEObasedContentWriting")
);
const Opening = lazy(() => import("./pages/Opening/Opening"));
const TheCompany = lazy(() => import("./pages/AboutUsNew/TheCompany"));

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense
        fallback={
          <div>
            <img
              src={loading}
              style={{ width: "100vw", objectFit: "cover", height: "100vh" }}
            />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          {/* IT supoort */}
          {/* web development */}
          <Route path="/web-development" element={<WebDevelopment />} />
          <Route path="/web-designing" element={<Webdesigning />} />
          <Route
            path="/wordpress-development"
            element={<Wordpressdevelopment />}
          />
          <Route path="/html5-web-development" element={<Html5development />} />
          <Route
            path="/microsites-and-landing-pages"
            element={<Micrositesandlanding />}
          />
          <Route
            path="/content-management-system"
            element={<Contentmangementsystem />}
          />
          {/* //ecommerce */}
          <Route
            path="/ecommerce-development"
            element={<ECommerceDevelopment />}
          />
          <Route path="/woo-commerce-development" element={<WooCommerce />} />
          <Route path="/shopify-development" element={<Shopify />} />
          <Route path="/magento-development" element={<Magento />} />
          {/* application development */}
          <Route
            path="/custom-software-development"
            element={<CustomerSoftwareDevelopment />}
          />
          <Route
            path="/software-development"
            element={<SoftwareDevelopment />}
          />
          <Route
            path="/open-source-development"
            element={<OpenSourceDevelopment />}
          />
          {/* mobile application  */}
          <Route path="/android-app-development" element={<AndroidDevelopment />} />
          <Route path="/mobile-app-development" element={<MobileDevelopment />} />
          <Route path="/ios-app-development" element={<IOSDevelopment />} />
          {/* hosted application */}
          <Route
            path="/hosted-web-development"
            element={<HostedWebDevelopment />}
          />
          <Route
            path="/hosted-application-development"
            element={<Hostedapplicationdevelopment />}
          />
          {/* web service and amc */}
          <Route path="/webservice-and-amc" element={<WebServiceAMC />} />
          <Route path="/web-mentainance-services-and-amc" element={<WebsiteMaintenanceServices />} />
          {/* media service */}
          {/* graphics */}
          <Route path="/corporate-designing" element={<CorporateBranding />} />
          <Route
            path="/logo-designing"
            element={<CreativeLogoCreation />}
          />
          <Route path="/product-designing" element={<ProductBranding />} />
          <Route path="/flyer-designing" element={<FlyerDesigning />} />
          <Route path="/brochure-designing" element={<BrochureDesigning />} />
          <Route
            path="/business-card-designing"
            element={<BusinessCardDesigning />}
          />
          <Route path="/graphic-designing" element={<GraphicDesigning />} />
          {/* //video and animation */}
          <Route
            path="/editing-and-post-production"
            element={<EditingandPostProduction />}
          />
          <Route path="/promotional-video" element={<PromotionalVideo />} />
          <Route path="/short-video-ads" element={<ShortVideoAds />} />
          <Route path="/spokesperson-video" element={<SpokespersonVideo />} />
          <Route path="/video-animation" element={<VideoandAnimation />} />
          {/* marketing */}
          {/*digital amrketing */}
          <Route
            path="/blog-creation-and-promotion"
            element={<BlogCreationAndPromotion />}
          />
          <Route path="/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/email-marketing" element={<EmailMarketing />} />
          <Route path="/contract-marketing" element={<ContractMarketing />} />
          <Route
            path="/pay-per-click-marketing"
            element={<PayPerClickMarketing />}
          />
          <Route
            path="/search-engine-optimization"
            element={<SearchEngineOptimization />}
          />
          <Route
            path="/social-media-marketing"
            element={<SocialMediaMarketing />}
          />
          <Route
            path="/social-media-optimization"
            element={<SocialMediaOptimization />}
          />
          <Route path="/devops" element={<DevOps />} />;
          <Route path="/ui-ux-designing" element={<UiUx />} />;{/* //email marketing */}
          <Route
            path="/business-marketing-service"
            element={<EmailMarketing />}
          />
          {/* //advertisingService */}
          <Route
            path="/google-ads-and-bing-ads"
            element={<GoogleAdWordsAndBingAds />}
          />
          <Route
            path="/google-my-business"
            element={<GoogleMyBusiness />}
          />
          <Route path="/advertising-service" element={<AdvertisingService />} />
          {/* writing */}
          {/* //legal technical */}
          <Route
            path="/copywriting"
            element={<BusinessCopywriting />}
          />
          <Route path="/itlegal-and-technical-writing" element={<ITLegalWriting />} />
          <Route path="/transcription" element={<Transcription />} />
          {/* //professional content writing*/}
          <Route
            path="/web-article-writing"
            element={<ArticlesWebandBlogPosts />}
          />
          <Route path="/creative-writing" element={<CreativeWriting />} />
          <Route
            path="/professional-content-writing"
            element={<ProfessionalContentWriting />}
          />
          <Route
            path="/seo-based-content-writing"
            element={<SEObasedContentWriting />}
          />
          {/* Career Page */}
          <Route path="/opening" element={<Opening />} />
          <Route path="/about-the-company" element={<TheCompany />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/career" element={<Careers />} />
          {/* Hiring From */}
          <Route path="/hiring-form" element={<Form />} />
        </Routes>
      </Suspense>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
