import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Button, Card, Image } from "antd";
import AboutOne from "../../../assets/images/Contact/connect.webp";
import AboutTwo from "../../../assets/images/Contact/directconnect.webp";
import Dot from "../../../assets/images/dot.png";

const Container = styled.div`
  padding-top: 5rem;
  transition: background-color 0.5s ease-out;
  background-color: ${(props) => props.bgColor};
`;


const Description = styled.p`
  font-size: 3rem;
  font-family: "Teko", sans-serif;
  font-weight: 550;
  color: #333;
  text-align: left;
  margin-bottom: 1rem;
  line-height: 120%;
`;

const Bullet = styled.p`
  font-size: 2rem;
  font-family: "Teko", sans-serif;
  font-weight: 500;
  text-align: start;
  margin-bottom: 0px;
 
  color: #333;
`;
const Arrow = styled(Image)`
  margin-bottom: 1.25rem;
`;

const BoldHeading = styled.div`
  text-align: left;
  font-size: 40px;
  font-weight: 600;
  color: #333;
  font-family: "Teko", sans-serif;
 
`;
const SubHeading = styled.div`
  font-size: 18px;
  font-weight: 500;
  font-family: "Teko", sans-serif;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-inline: 0.5rem;
  color: #333;
  text-align:left;
`;
const StyledRow = styled(Row)`
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
`;
const QuoteDesc = styled.p`
  font-size: 30px;
  font-family: "Teko", sans-serif;
  font-weight: 600;
  text-align: start;
  margin-bottom: 0px;
  text-align: center;
  color: white;
`;

const Information = () => {


  function handleChange(value) {
    console.log(`selected ${value}`);
  }
  return (
    <Row style={{ marginTop: "5vh", marginBottom: "5vh", width:"100vw"}}>
    <Col
      xs={24}
      sm={24}
      md={12}
      lg={12}
      xl={12}
      xxl={12}
      style={{ backgroundColor: "white" }}
    >
      <Row align={"middle"} style={{ height: "100%", width:"100vw" }}>
        <Col span={24}>
          <Row
            style={{
              backgroundColor: "#e1e1e1",
              padding: "5rem",
            }}
          >
            <Col span={24} >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "3.5rem",
                  height: 1,
                  border: "1px solid black",
                  width: "100px",
                  flexDirection: "row",
                  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                  marginRight: "2rem",
                  fontSize: "2rem"
                }}
              />
            </div>
            <div>
              <QuoteDesc style={{ marginTop: "2rem" ,color:'black'}}>
               Contact Us 
              </QuoteDesc>
            </div>
          </div>
          <BoldHeading style={{ color: "black" }}>
          If you prefer, send us an email for new business opportunities or job openings.
          </BoldHeading>
        </Col>
            <SubHeading>
            Welcome to Mac World Technology! We are dedicated to providing our customers with state-of-the-art solutions as a leading technology company with a base in Dubai, United Arab Emirates. Our objective is to exceed customer expectations by offering state-of-the-art technological products and services. We really appreciate your feedback, and we think it is a key part of making our services better and better. We are ready to hear your question, suggestion, or comment and to help.

            </SubHeading>
          </Row>
        </Col>
      </Row>
    </Col>

    {/* <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <StyledRow align={"middle"}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Col align={"middle"} style={{ height: "100%", padding: "1rem" }}>
                <SubHeading style={{textAlign:'left',fontWeight:'bold'}}>Connect with Mac World: We're Just a Click Away!</SubHeading>
                <SubHeading  style={{textAlign:'left'}}>
                Get in touch with us effortlessly through our Contact Us page. Our team of tech enthusiasts is always ready to assist you with any queries or concerns you may have regarding Mac World products and services.                </SubHeading>
              </Col>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              style={{ backgroundColor: "#4b4b4b" }}
            >
              <Image src={AboutOne} preview={false} />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              style={{ backgroundColor: "#01497c"}}
            >
              <Image src={AboutTwo} preview={false} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Row align={"middle"} style={{ height: "100%", padding: "1rem" }}>
                <SubHeading style={{textAlign:'left',fontWeight:'bold'}}>Reach Out to Mac World: Experience Exceptional Support!</SubHeading>
                <SubHeading  style={{textAlign:'left'}}>
                  {" "}
                  Experience the power of personalized assistance by contacting Mac World's dedicated support team. From troubleshooting to product recommendations, we're here to provide you with exceptional service and ensure your Mac World experience is nothing short of extraordinary                </SubHeading>
              </Row>
            </Col>
          </StyledRow>
        </Col> */}
    
  </Row>
  

  );
};

export default Information;
