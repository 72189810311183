import React from "react";
import { Row, Col } from "antd";
import { Card } from "antd";
import styled from "styled-components";
const { Meta } = Card;
const BoldHeading = styled.div`
  text-align: left;
  font-size: 7rem;
  font-weight: 600;
  color: #333;

  font-family: "Teko", sans-serif;
  @media (max-width: 768px) {
    font-size: 6rem;
  }
`;
const StyleMeta = styled(Meta)`
  text-align: center;
  font-family: "Teko", sans-serif;
  font-size: 2rem;
  font-weight: 500;
  color: white !important;

`;
const StyleCard = styled(Card)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 150px;

`;
const SubHeading = styled.div`
text-align: center;
  font-size: 2rem;
  font-weight: 500;
  font-family: "Teko", sans-serif;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: white;
  padding-inline: 0.5rem;
  @media (max-width: 768px) {
    font-size: 1.7rem;
  }
`;
const Wheregoing = () => {
  return (
    <>
    <Row style={{marginTop:'5vh'}} align={"middle"}>
      <Col span={24}>
        <Row gutter={[20,20]} justify={"center"} align={"middle"}>
          <Col xs={24} sm={12} md={8} lg={4} xl={4} xxl={4} >
            <StyleCard
              hoverable
              style={{backgroundColor:"#333"}}
            >
              <SubHeading  >Software Development
                </SubHeading>

              
            </StyleCard>
          </Col>

          <Col xs={24} sm={12} md={8} lg={4} xl={4} xxl={4}>
            <StyleCard
              hoverable
              style={{backgroundColor:"#333"}}

            >

              <SubHeading>Graphic Designing</SubHeading>
          
            </StyleCard>
          </Col>

          <Col xs={24} sm={12} md={8} lg={4} xl={4} xxl={4}>
            <StyleCard
              hoverable
              style={{backgroundColor:"#333"}}

            >
              <SubHeading>Digital Marketing</SubHeading>

            </StyleCard>
          </Col>
          <Col xs={24} sm={12} md={8} lg={4} xl={4} xxl={4}>
          <StyleCard
              hoverable
              style={{backgroundColor:"#333"}}

            >
              <SubHeading>Call Center</SubHeading>

            </StyleCard>
            {/* <Card
              hoverable
              cover={<img alt="example" src="your-image-url.jpg" />}
            >
              <Meta title="Card Title" description="Card Description" />
            </Card> */}
          </Col>
          <Col xs={24} sm={12} md={8} lg={4} xl={4} xxl={4}>
            <StyleCard
              hoverable
              style={{backgroundColor:"#333"}}

            >
                            <SubHeading>Early Career</SubHeading>

            
            </StyleCard>
          </Col>
        </Row>
      </Col>
    </Row>
   
    </>
  );
};

export default Wheregoing;
