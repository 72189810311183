import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Tag } from "antd";


const tags = [
  "Local roots",
  "Global mindset",
  "Personalized service",
  "Think global",
  "World-class solutions",
];
const Wrapper = styled.div`
  padding-top: 10rem;
  transition: background-color 0.5s ease-out;
  background-color: ${(props) => props.bgColor};
`;

const UnderLineOne = styled.span`
  font-size: 2.5rem;
  font-weight: 600;
  color: #333;

  position: relative;
  font-family: "Teko", sans-serif;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 90px;
    height: 2px;
    background-color: #b0b0b0;
    transform-origin: left;
    font-family: "Teko", sans-serif;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0rem;
    width: 90px;
    height: 2px;
    background-color: #01497c;
    transition: transform 0.3s ease-in-out;
    transform: scaleX(0);
    transform-origin: left;
  }

  &:hover::after {
    transform: scaleX(1);
  }
`;

const BoldHeading = styled.div`
  text-align: left;
  font-size: 7rem;
  font-weight: 600;
  color: #333;

  font-family: "Teko", sans-serif;
  @media (max-width: 768px) {
    font-size: 6rem;
  }
`;
const SubHeading = styled.div`
  text-align: left;
  font-family: "Teko", sans-serif;
  font-size: 2rem;
  font-weight: 500;
  color: #333;

`;
const HomeGrown = () => {
  const [bgColor, setBgColor] = useState("#fff");

  useEffect(() => {
    const handleScroll = () => {
      const yPos = window.pageYOffset;
      console.log(yPos);

      if (yPos >= 2760) {
        setBgColor("white");
      } else {
        setBgColor("#E2EBF5");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>

      <Wrapper bgColor={bgColor} >
        <Row justify={"center"} align={"middle"} style={{ paddingTop: "5vh",paddingBottom:'5vh' }}>
          <Col xs={22} sm={22} md={22} lg={20} xl={20} xxl={20}>
            <Row style={{ marginBottom: "5vh" }}>
              <Col xs={22} sm={22} md={20} lg={20} xl={16} xxl={16} >
                <BoldHeading
                 
                >
                  Homegrown service, global thinking.
                </BoldHeading>
                <SubHeading
                 
                >
                  Our core team at Macworld Technology is based on the
                  picturesque Island Krk, Croatia, and we are thrilled to work
                  with clients from all over the world. We operate on a remote
                  basis and leverage our streamlined processes to make
                  collaboration with clients effortless and efficient. <br />{" "}
                  <br />
                  At Macworld Technology, we recognize that the world is
                  constantly evolving, and businesses need to adapt to stay
                  ahead. Hence, our design practice is continuously improving,
                  and we are always exploring new ways to deliver exceptional
                  results to our clients. <br /> <br /> We like to think this
                  offers our clients a unique combination — service they can
                  depend on, with the freedom to collaborate at scale.
                </SubHeading>
              </Col>
            </Row>
            <Row
              justify={"center"}
              style={{ marginTop: "2rem", marginBottom: "4rem" }}
            >
              <Col span={24}>
                <UnderLineOne style={{ marginRight: "2rem" }}>
                  Explore
                </UnderLineOne>
                {tags.map((tag) => (
                  <Tag
                    key={tag}
                    style={{
                      borderRadius: 40,
                      padding: "2rem",
                      width: "auto",
                      textAlign: "center",
                      color: "white",
                      fontWeight: "600",
                      fontSize: "2rem",
                      paddingInline: "3rem",
                      backgroundColor: "#333",
                      border: "0",
                      margin: "1rem",
                    }}
                  >
                    {tag}
                  </Tag>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Wrapper>
    </>
  );
};

export default HomeGrown;