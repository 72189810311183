import React from "react";
import styled from "styled-components";
import { Typography,Button,Grid,Row,Col } from "antd";
import { Colors } from "../../../common/theme/theme";
import EssentialImg from "../../../assets/images/careersmallbanner.jpg";
import { Link } from 'react-router-dom';
const { useBreakpoint } = Grid;

const { Title } = Typography;

const BannerWrapper = styled.div`
  height: 500px;
  position: relative;
  background-image: url(${EssentialImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 10vh;
  margin-top: 10vh;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;



const BannerContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const BannerText = styled(Title)`
  color: white;
`;
const BoldHeading = styled.div`
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color:white;
  font-family: "Teko", sans-serif;
 
`;
const SubHeading = styled.div`
  font-size: 18px;
  font-weight: 500;
  font-family: "Teko", sans-serif;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-inline: 0.5rem;
  color:white;
  text-align:center;
`;
const MWButton = styled(Button)`
  && {
    background-color: 'white';
    border: 0;
    color: ${Colors?.primary};
    margin-inline: 1rem;
    height: 40px;
    font-weight: 600;
    font-size:18px;
    padding-inline: 3rem;
    padding-bottom:1rem;
    &:hover {
      color: ${Colors?.primary};
    }
  }
`;

const SingleBanner = () => {

  return (
<>
<div >

<BannerWrapper >
      <BannerContent>
        <SubHeading>Join our talent community</SubHeading>
        <BoldHeading>LIFE'S ESSENTIALS</BoldHeading>
        <SubHeading>
        Join our talented and passionate team at Macworld technology and embark on an exciting journey of professional growth and fulfillment. As a leading player in the food service industry, we are committed to creating an environment that fosters creativity, innovation, and personal development.
        </SubHeading>

        <Link to={'/opening'}>
        <MWButton style={{marginTop:'2rem'}}>Openings</MWButton>
        </Link>

      </BannerContent>

    </BannerWrapper>


    </div>
</>
  );
};

export default SingleBanner;
