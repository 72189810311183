import React from "react";
import styled from "styled-components";
import { Button, Grid, Row, Col, Image } from "antd";
import { Link } from "react-router-dom";
import { Colors } from "../../../common/theme/theme";
import Lifeimg from '../../../assets/images/Home/lifeatmac.jpg'


import { MWButtonTwo } from "../../../common/GlobalStyles/global";
import { StyledImage1 } from "../../../common/GlobalStyles/global";
import { WrapperDis } from "../../../common/GlobalStyles/global";
import { BoldHeadingQuote } from "../../../common/GlobalStyles/global";
import { SubHeading } from "../../../common/GlobalStyles/global";
const { useBreakpoint } = Grid;















const Discover = () => {
  const screens = useBreakpoint();

  return (
    <>
      <div
        style={{
          marginTop: "10vh",
          paddingInline: screens?.lg ? "0px" : "2rem",
         
        }}
      >
        <WrapperDis>
        

          <Row
            justify={"center"}
            style={{
              marginLeft: screens?.lg ? "90px" : "0px",
              marginTop: "5vh",
              marginBottom: "5vh",
            }}
          >
            <Col span={24}>
              <Row justify={"center"} align={"top"}>
                
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <StyledImage1
                  preview={false}
                    src={Lifeimg}
                    height={500}
                    width={screens?.lg ?"90%":"100%"}
                    style={{ objectFit: "cover" }}
                  />
                  <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                   
                  </Col>
                </Col>
                
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} >
                  <Row align={"top"}  >
                    <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
                      <div
                        style={{
                          height: "100px",
                          width:"100px",
                          borderBottom: "1px solid #01497c",
                        }}
                      ></div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <SubHeading
                        style={{
                          color: "#01497c",
                          paddingInline: "0",
                          marginTop: "1rem",
                          fontWeight:'bold',
                          fontSize:'20px'
                        }}
                      >
                        CAREERS
                      </SubHeading>{" "}
                    </Col>
                    <Col span={23}>
                      <BoldHeadingQuote style={{textAlign:"left" , color:" #333"}}>
                        Life at Mac World Technology
                      </BoldHeadingQuote>
                    </Col>
                    <Row>
                      {" "}
                      <Col  xs={24} sm={24} md={22} lg={22} xl={22} xxl={22} >
                        <SubHeading style={{ color: "black" }}>
                          {" "}
                          Empowering Careers, Be Part of Something Bigger.
                        </SubHeading>
                        <SubHeading
                            style={{
                              color:'black'
                            }}
                          >
                     We offer exciting career opportunities in a dynamic and innovative company. Join us and be part of our success story!
                          </SubHeading>
                          <Link to={'/career'}>

                          <MWButtonTwo>READ THE FULL STORY</MWButtonTwo>
                          </Link>
                      </Col>
                    </Row>
                  </Row>
                </Col>

                
              </Row>
            </Col>
          </Row>
        </WrapperDis>

      </div>
    </>
  );
};

export default Discover;
