import React, { useState } from "react";
import { Collapse } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import usflag from "../../assets/images/countryimages/united-arab-emirates.png";
import styled from "styled-components";

const { Panel } = Collapse;
const SubHeading = styled.div`
  font-size: 18px;
  font-weight: 500;
  font-family: "Teko", sans-serif;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-inline: 0.5rem;
  color: white;
`;
const BoldHeading = styled.div`
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  color: white;
  font-family: "Teko", sans-serif;
`;

const UaeAddress = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  const [isOpen, setIsOpen] = useState();

  const handleBarClick = () => {
    setIsOpen(!isOpen);
    handleClick();
  };

  return (
    <div style={{ background: "transparent" }}>
      <Collapse
        bordered={false}
        activeKey={isOpen ? "1" : ""}
        style={{ background: "transparent", width: "25rem" }}
      >
        <BoldHeading style={{ marginLeft: "10px" }}>UAE</BoldHeading>
        <Panel
          showArrow={false}
          style={{}}
          header={
            <div
              onClick={handleBarClick}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "-2rem",
              }}
            >
              {isClicked ? (
                <MinusCircleOutlined
                  style={{ fontSize: "24px", color: "white" }}
                />
              ) : (
                <PlusCircleOutlined
                  style={{ fontSize: "24px", color: "white" }}
                />
              )}
              <img src={usflag} style={{ width: "50px", height: "50px" }} />
            </div>
          }
          key="1"
        >
          <SubHeading>
            ■ HeadQuarter Office No:605, The Exchange Tower, Happiness street,
            Business Bay, Sheikh Zayed Road, Dubai, UAE
          </SubHeading>
        </Panel>
      </Collapse>
    </div>
  );
};

export default UaeAddress;
