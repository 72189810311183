import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
const Wrapper = styled.div`
  padding-top: 5vh;
  padding-bottom: 5vh;
  transition: background-color 0.5s ease-out;
  background-color: ${(props) => props.bgColor};
`;

const BoldHeading = styled.div`
  text-align: left;
  font-size: 7rem;
  font-weight: 600;
  color:white;

  font-family: "Teko", sans-serif;
  @media (max-width: 768px) {
    font-size: 6rem;
  }
`;
const SubHeading = styled.div`
  text-align: left;
  font-family: "Teko", sans-serif;
  font-size: 2rem;
  font-weight: 500;
  color:white;

`;
const GuidingPrinciple = () => {
    const [bgColor, setBgColor] = useState("#fff");




  return (
    <>
<Wrapper bgColor={"#333"}>

<Row  justify={"center"}>
          <Col
            xs={22}
            sm={22}
            md={22}
            lg={22}
            xl={22}
            xxl={22}
          >
               <Row style={{ marginBottom: "5vh" }} justify={"start"}>
              <Col xs={22} sm={22} md={20} lg={20} xl={16} xxl={16} >
            <BoldHeading
       
             
            >
          Guiding principles
            </BoldHeading>
            <SubHeading
              
            >
Get a sense of what’s it like working at team MacWorld.

</SubHeading>
            </Col></Row>
          </Col>
        </Row>
      <Row  justify={"center"}>
       
        <Col span={20}>
          <Row style={{ marginTop: "5rem" }}>
            {[
              {
                id: 1,
                title: "Our mission",
                desc: "Build things, try things, break things, make them better and keep learning. Test your ideas on real people, and use the latest tools. We empower our team to solve problems creatively and have fun doing it.",
              },
              {
                id: 2,
                title: "Get your hands dirty.",
                desc: "Build things, try things, break things, make them better and keep learning. Test your ideas on real people, and use the latest tools. We empower our team to solve problems creatively and have fun doing it.",
              },
              {
                id: 3,
                title: "Make things that matter.",
                desc: "Our clients are not sexy — but they make the world go round. If you get excited by building tech that really changes people’s lives, you’ll thrive on team BB. Our team are passionate about solving sticky problems for healthcare, communications, media and infrastructure technology. You’ll get to challenge yourself and use your brain in completely new ways.    ",
              },
              {
                id: 4,
                title: "Be heard.",
                desc: "We’re a young and agile company and try to maintain a flat hierarchy. We want our team to get a say in how we do things, and feel a sense of ownership. We’ll expect and rely on you to keep everyone on their toes. We empower you to bring your ideas to the table, improve our processes and take initiative for building the kind of agency you want to work for.",
              },
              {id:5,
                title:'Win back some life.',
                desc:'One of the perks of our remote team is that we genuinely trust you to get your work done. Because, well, you’re an adult! We accommodate flexible working schedules and a liberal vacation policy, so you can find a balance that works for you.'
            }
           
            ].map((x) => {
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  xl={12}
                  xxl={12}
                  style={{ padding: "2rem" }}
                >
                  <div
                   
                  >
                    <Row align={"middle"}>
                    
                      <p
                        style={{
                          textAlign: "center",
                          fontFamily: "'Teko', sans-serif",

                          fontSize: "5rem",
                          fontWeight: "600",
                          color: "white",
                        }}
                      >
                        {x?.title}
                      </p>
                    </Row>
                    <Row>
                      <p
                        style={{
                          fontFamily: "'Teko', sans-serif",

                          fontSize: "2rem",
                          fontWeight: "500",
                          color: "#333",
                          marginTop: "4rem",
                          color: "white",
                          marginBottom: "4rem",
                        }}
                      >
                        {x?.desc}
                      </p>
                    </Row>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row></Wrapper>
    </>
  );
};

export default GuidingPrinciple;
