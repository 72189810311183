import React from "react";
import Carousel from "../../components/ContactScreen/Carousel";
import Information from "../../components/ContactScreen/Information";
import Cards from "../../components/ContactScreen/Cards";
import Thankyou from "../../components/ContactScreen/Thankyou";
import {Helmet} from 'react-helmet';
import GetQuote from "../../components/HomeScreen/GetQuote";


const Contact = () => {

  document.addEventListener("DOMContentLoaded", function() {
    // Check if the URL contains a hash
    if (window.location.hash) {
        // Get the target element using the hash (without the # symbol)
        const targetId = window.location.hash.substring(1);
        const targetElement = document.getElementById(targetId);
        
        // Scroll to the target element
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }
  });
  return (
    <>
 <Helmet>
                        <meta charSet="utf-8" />
                        <title>Contact Us | Reach Out For Customer Support</title>
                        <meta id="meta-description" name="description" content="Need assistance? Contact Mac World Technology for reliable customer support. Find our contact information" />
                <link rel="canonical" href="https://macworldtechnology.com/contact" />
          </Helmet>
      <Carousel />   
      <Information />
      <div id="section1">
      <GetQuote title="Contact Form" description="You may contact us using the simple contact form on our website. Our team can be contacted directly through this form if you have any questions, comments, or requests. Please be assured that all information you provide will be handled with the utmost discretion, protecting your privacy and security. Use the contact form to send us a note, and one of our staff members will respond right away."/>
      </div>
      <Cards/>
      <Thankyou/>
   
    </>
  );
};

export default Contact;
