import React, { useState } from "react";
import { Drawer, Menu, Collapse, Row, Col, Image } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../assets/images/logoblue.png";
import { Colors } from "../theme/theme";
import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  WhatsAppOutlined,
  TwitterSquareFilled,
  YoutubeFilled,
  SkypeFilled,
} from "@ant-design/icons";
const { SubMenu } = Menu;
const { Panel } = Collapse;
const SubText = styled.div`
  margin: 1rem 0rem;
  font-family: "Teko", sans-serif;
  font-weight: 600;
`;
const MobileNav = ({ temp }) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(!visible);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <Row
        justify={"space-between"}
        align={"middle"}
        style={{
          height: "85px",
          paddingInline: "1rem",
          paddingTop: "1rem",
          backgroundColor: "white",
        }}
      >
        <Col>
          <img
            src={Logo}
            width={170}
            height={70}
            style={{ objectFit: "contain", marginTop: "0.5rem" }}
          />
        </Col>
        <Col>
          <MenuOutlined
            onClick={showDrawer}
            style={{ fontSize: "28px", color: "#333", paddingInline: "3rem" }}
          />
        </Col>
      </Row>
      <Drawer title={""} closable={false} onClose={onClose} visible={visible}>
        <Menu mode="inline">
          <Row style={{ marginTop: "76px" }}></Row>

          <Menu.Item key="1">
            <Link to={"/home"}>Home</Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to={"/about-the-company"}>Enterprise</Link>
          </Menu.Item>

          <SubMenu key="3" title="Dress Your Business">
            <SubMenu key="3_1" title="Web Development" path="/webdevelopment">
              <Link to={"/webdevelopment"}> Web Development</Link>

              {[
                {
                  id: 1,
                  title: "Website Development",
                  path: "/web-development",
                },
                {
                  id: 2,
                  title: "Website Designing",
                  path: "/web-designing",
                },

                {
                  id: 3,
                  title: "WordPress Development",
                  path: "/wordpress-development",
                },
                {
                  id: 4,
                  title: "HTML5 Web Development",
                  path: "/html50-web-development",
                },
                {
                  id: 5,
                  title: "Micro Sites And Landing Pages",
                  path: "/microsites-and-landing-pages",
                },
                {
                  id: 6,
                  title: "Content Management System",
                  path: "/content-management-system",
                },
                {
                  id: 7,
                  title: "Website Mantainance Services & AMC",
                  path: "/web-mentainance-services-and-amc",
                },
              ].map((item, index) => {
                return (
                  <Menu.Item key={item?.id}>
                    <Link to={item?.path}>{item?.title}</Link>
                  </Menu.Item>
                );
              })}
            </SubMenu>
            <SubMenu key="3_2" title="Ecommerce">
              {[
                {
                  id: 1,
                  title: "Ecommerce Development",
                  path: "/ecommerce-development",
                },
                { id: 2, title: "Magento", path: "/magento-development" },
                { id: 3, title: "Shopify", path: "/shopify-development" },
                {
                  id: 4,
                  title: "Woo Commerce",
                  path: "/woo-commerce-development",
                },
              ].map((item, index) => {
                return (
                  <Menu.Item key={item?.id}>
                    <Link to={item?.path}>{item?.title}</Link>
                  </Menu.Item>
                );
              })}
            </SubMenu>

            <SubMenu key="3_3" title="Application Development">
              {[
                {
                  id: 1,
                  title: "Software Development",
                  path: "/software-development",
                },
                {
                  id: 2,
                  title: "Custom Software Development",
                  path: "/custom-software-development",
                },

                {
                  id: 3,
                  title: "Open Source Development",
                  path: "/open-source-development",
                },
              ].map((item, index) => {
                return (
                  <Menu.Item key={item?.id}>
                    <Link to={item?.path}>{item?.title}</Link>
                  </Menu.Item>
                );
              })}
            </SubMenu>
            <SubMenu key="3_4" title="Mobile Application ">
              {[
                {
                  id: 1,
                  title: "Mobile Development",
                  path: "/mobile-app-development",
                },
                {
                  id: 2,
                  title: "IOS Development",
                  path: "/ios-app-development",
                },
                {
                  id: 3,
                  title: "Android - Mobile & Tablet",
                  path: "/android-app-development",
                },
              ].map((item, index) => {
                return (
                  <Menu.Item key={item?.id}>
                    <Link to={item?.path}>{item?.title}</Link>
                  </Menu.Item>
                );
              })}
            </SubMenu>

            <SubMenu key="3_5" title="Hosted Application ">
              {[
                {
                  id: 1,
                  title: "Hosted Application Development",
                  path: "/hosted-web-development",
                },
                {
                  id: 2,
                  title: "Hosted Web Development ",
                  path: "/hosted-application-development",
                },
              ].map((item, index) => {
                return (
                  <Menu.Item key={item?.id}>
                    <Link to={item?.path}>{item?.title}</Link>
                  </Menu.Item>
                );
              })}
            </SubMenu>
            {/* <SubMenu key="3_6" title="Web Service & AMC ">
              {[
                {
                  id: 1,
                  title: "Web Services and AMC",
                  path: "/webservice-and-amc",
                },
                {
                  id: 2,
                  title: "Website Maintainance Services",
                  path: "/WMC",
                },
              ].map((item, index) => {
                return (
                  <Menu.Item key={item?.id}>
                    <Link to={item?.path}>{item?.title}</Link>
                  </Menu.Item>
                );
              })}
            </SubMenu> */}
          </SubMenu>

          <SubMenu key="4" title="Compose Business Lingo">
            <SubMenu key="4_1" title="Graphics">
              {[
                { id: 1, title: "Graphic Designing", path: "/graphic-designing" },
                { id: 2, title: "Product Branding", path: "/product-designing" },
                {
                  id: 3,
                  title: "Corporate Branding",
                  path: "/corporate-designing",
                },
                {
                  id: 4,
                  title: "Creative Logo Creation",
                  path: "/logo-designing",
                },
                {
                  id: 5,
                  title: "Brochure Designing",
                  path: "/brochure-designing",
                },
                {
                  id: 6,
                  title: "Business Card Designing",
                  path: "/businesscard-designing",
                },
                {
                  id: 7,
                  title: "Flyer Designing",
                  path: "/flyer-designing",
                },
              ].map((item, index) => {
                return (
                  <Menu.Item key={item?.id}>
                    <Link to={item?.path}>{item?.title}</Link>
                  </Menu.Item>
                );
              })}
            </SubMenu>
            <SubMenu key="4_2" title="Video & Animation">
              {[
                {
                  id: 1,
                  title: "Videos & Animation",
                  path: "/video-animation",
                },
                {
                  id: 2,
                  title: "Promotional Videos",
                  path: "/promotional-video",
                },
                {
                  id: 3,
                  title: "Editing & Post Production",
                  path: "/editing-and-post-production",
                },

                {
                  id: 4,
                  title: "Spokesperson  Video",
                  path: "/spokesperson-video",
                },

                { id: 5, title: "Short Video Ads", path: "/short-video-ads" },
              ].map((item, index) => {
                return (
                  <Menu.Item key={item?.id}>
                    <Link to={item?.path}>{item?.title}</Link>
                  </Menu.Item>
                );
              })}
            </SubMenu>
          </SubMenu>

          {/* //marketing */}
          <SubMenu key="5" title="Set Marketing Tone">
            <SubMenu key="5_1" title="Digital Marketing">
              {[
                {
                  id: 1,
                  title: "Digital Marketing",
                  path: "/digital-marketing",
                },

                {
                  id: 2,
                  title: "Search Engine Optimization",
                  path: "/search-engine-optimization",
                },
                {
                  id: 3,
                  title: "Social Media Optimization",
                  path: "/social-media-optimization",
                },
                {
                  id: 4,
                  title: "Social Media Marketing",
                  path: "/social-media-marketing",
                },
                {
                  id: 5,
                  title: "Pay Per Click Marketing",
                  path: "/pay-per-click-marketing",
                },
                {
                  id: 6,
                  title: "Blog Creation and Promotion",
                  path: "/blog-creation-and-promotion",
                },
                {
                  id: 7,
                  title: "Content Marketing",
                  path: "/contract-marketing",
                },
                {
                  id: 8,
                  title: "Email Marketing",
                  path: "/business-marketing-service",
                },
              ].map((item, index) => {
                return (
                  <Menu.Item key={item?.id}>
                    <Link to={item?.path}>{item?.title}</Link>
                  </Menu.Item>
                );
              })}
            </SubMenu>
            {/* <SubMenu key="5_2" title="Email Marketing">
              {[
                {
                  id: 1,
                  title: "Email Marketing",
                  path: "/business-marketing-service",
                },
              ].map((item, index) => {
                return (
                  <Menu.Item key={item?.id}>
                    <Link to={item?.path}>{item?.title}</Link>
                  </Menu.Item>
                );
              })}
            </SubMenu> */}
            <SubMenu key="5_3" title="Advertising Service">
              {[
                {
                  id: 1,
                  title: "Advertising Service",
                  path: "/advertising-service",
                },
                {
                  id: 2,
                  title: "Google AdWords And Bing Ads",
                  path: "/google-ads-and-bing-ads",
                },
                {
                  id: 3,
                  title: "Google My Business",
                  path: "/google-my-business",
                },
              ].map((item, index) => {
                return (
                  <Menu.Item key={item?.id}>
                    <Link to={item?.path}>{item?.title}</Link>
                  </Menu.Item>
                );
              })}
            </SubMenu>
          </SubMenu>
          <SubMenu key="6" title="Magic With Words">
            <SubMenu key="6_1" title="Professional Content Writing">
              {[
                {
                  id: 1,
                  title: "Professional Content Writing",
                  path: "/professional-content-writing",
                },
                {
                  id: 2,
                  title: "Web Article Writing",
                  path: "/web-article-writing",
                },
                {
                  id: 3,
                  title: "SEO Based Content Writing",
                  path: "/seo-based-content-writing",
                },
                { id: 4, title: "Creative Writing", path: "/creative-writing" },
              ].map((item, index) => {
                return (
                  <Menu.Item key={item?.id}>
                    <Link to={item?.path}>{item?.title}</Link>
                  </Menu.Item>
                );
              })}
            </SubMenu>
            <SubMenu key="6_2" title="Legal & Technical">
              {[
                {
                  id: 1,
                  title: "Legal & Technical Writing",
                  path: "/itlegal-and-technical-writing",
                },
                {
                  id: 2,
                  title: "Copywriting",
                  path: "/copywriting",
                },
                // { id: 2, title: "IT Legal Writing", path: "/itlegal-writing" },

                { id: 3, title: "Transcription", path: "/transcription" },
              ].map((item, index) => {
                return (
                  <Menu.Item key={item?.id}>
                    <Link to={item?.path}>{item?.title}</Link>
                  </Menu.Item>
                );
              })}
            </SubMenu>
          </SubMenu>
          <Menu.Item key="7">
            <Link to={"/career"}>Become Partners</Link>
          </Menu.Item>

          <Menu.Item key="8">
            <Link to={"/contact"}>Knock and Ringing</Link>
          </Menu.Item>
          <Row
            gutter={[30, 30]}
            style={{ textAlign: "start", marginTop: "1rem" }}
            justify={"space-evenly"}
          >
            <Col span={24}>
              <a
                href="https://www.facebook.com/Macworldtechnologies"
                target="_blank"
              >
                <FacebookFilled
                  style={{
                    fontSize: "26px",
                    color: "#3b5998",
                    marginRight: "10px",
                  }}
                />
              </a>
              <a
                href="https://www.instagram.com/macworldtechnologies/"
                target="_blank"
              >
                <InstagramFilled
                  style={{
                    fontSize: "26px",
                    color: "#e1306c",
                    marginRight: "10px",
                  }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/mac-world-technologies/"
                target="_blank"
              >
                <LinkedinFilled
                  style={{
                    fontSize: "26px",
                    color: "#0077b5",
                    marginRight: "10px",
                  }}
                />
              </a>
              <WhatsAppOutlined
                style={{
                  fontSize: "26px",
                  color: "#25d366",
                  marginRight: "10px",
                }}
              />
              <TwitterSquareFilled
                style={{
                  fontSize: "26px",
                  color: "#1da1f2",
                  marginRight: "10px",
                }}
              />
              <YoutubeFilled
                style={{
                  fontSize: "26px",
                  color: "#ff0000",
                  marginRight: "10px",
                }}
              />
              <SkypeFilled
                style={{
                  fontSize: "26px",
                  color: "#00aff0",
                  marginRight: "10px",
                }}
              />
            </Col>
          </Row>
        </Menu>
      </Drawer>
    </>
  );
};

export default MobileNav;
