import React, { useState } from "react";
import { Row, Col, Image, Grid } from "antd";

import styled, { keyframes } from "styled-components";

import Bannerimg from '../../../assets/images/Home/latestservicebg.png'
import { Colors } from "../../../common/theme/theme";
import WebAppimg from "../../../assets/images/Home/webapp.gif";
import Soft from "../../../assets/images/Home/softdev.gif";
import Devopsimg from "../../../assets/images/Home/devops.gif";
import uiux from "../../../assets/images/Home/UIUX.gif";
import ecom from "../../../assets/images/Home/Ecommerce.gif";
import whiteecom from "../../../assets/images/Home/whiteecom.gif";

import ppcimg from "../../../assets/images/Home/ppc.gif";
import whiteppc from "../../../assets/images/Home/whiteppc.gif";
import whitewebapp from "../../../assets/images/Home/whitewebapp.gif";
import whiteuiux from "../../../assets/images/Home/whiteuiux.gif";

import digitalmark from "../../../assets/images/Home/digitalmark.gif";
import whitedigital from "../../../assets/images/Home/whitedigital.gif";
import whitedevops from "../../../assets/images/Home/whitedevops.gif";
import whitegraphic from "../../../assets/images/Home/whitegraphic.gif";


import LinkImg from "../../../assets/images/Home/link.png";
import graphicdesi from "../../../assets/images/Home/graphicdes.gif";
import whitesoftdev from "../../../assets/images/Home/whitesoftdev.gif";

import { Wrapper } from "../../../common/GlobalStyles/global";
import { BoldHeading } from "../../../common/GlobalStyles/global";
import { SubHeading } from "../../../common/GlobalStyles/global";
import { CardHeading } from "../../../common/GlobalStyles/global";
import { StyledCol } from "../../../common/GlobalStyles/global";
import { CardPara } from "../../../common/GlobalStyles/global";
import { LinkBox } from "../../../common/GlobalStyles/global";
import { StyledImage } from "../../../common/GlobalStyles/global";

import { Link } from "react-router-dom";
const { useBreakpoint } = Grid;







const StyledImageLogo = styled(Image)`
  width: 48;
  height: 48;
`;



const LatestService = () => {
  const screens = useBreakpoint();
  const [hover, setHover] = useState(0);

  return (
    <>
      <Wrapper>
        <Row justify={"center"}>
          <Col span={24}>
            <Row justify={"center"}>
              <Col span={22}>
                <BoldHeading style={{fontSize:"2.5rem" , fontWeight:"600" }}>Our Services</BoldHeading>
                <SubHeading>
                At Mac World Technology, we offer a wide variety of services intended to meet the various needs of businesses. Our services are purposefully designed to provide the greatest value and guarantee easy deployment. Our services at Mac World Technology are created to meet the specific issues that today's organizations confront, ensuring that they stay on the cutting edge of technology and accomplish their objectives.
                </SubHeading>
              </Col>


              <Col span={22}>
                <Row justify={screens?.lg ? "end" : "center"} >
                  {[
                    {
                      id: 1,
                      title: "Ecommerce development",
                      desc: "Transform your vision into a thriving online store with our expert Ecommerce development services. Elevate your business today!",
                      img: ecom,
                      hoverimg:whiteecom,
                      path: "/ecommerce-development"
                    },
                    {
                      id: 2,
                      title: "Customer Software Development",
                      desc: "Unlock limitless possibilities with our Custom Software Development – turning your vision into digital reality.",
                      img: Soft,
                      hoverimg:whitesoftdev,
                      path: "/custom-software-development"
                    },
                   
                    {
                      id: 3,
                      title: "Website Development",
                      desc: "Transform your ideas into a stunning online presence with our expert website development services. Let's build your digital success together!",
                      img: WebAppimg,
                      hoverimg:whitewebapp,
                      path: "/web-development"
                    },
                    {
                      id: 4,
                      title: "Digital Marketing",
                      desc: "Unlock your brand's full potential with our strategic Digital Marketing solutions today! Get brands to one of top brands now!",
                      img: digitalmark,
                      hoverimg:whitedigital,
                      path: "/digital-marketing"
                    },
                    ,
                    {
                      id: 5,
                      title: "PPC Marketing:",
                      desc: "Supercharge your business growth with targeted results – Try our PPC Marketing services today!",
                      img: ppcimg,
                      hoverimg:whiteppc,
                      path: "/pay-per-click-marketing"
                    },
                    ,
                    {
                      id: 6,
                      title: "Ui Ux Designing",
                      desc: "Transform user experiences with our exceptional UI/UX design services. Elevate your digital presence today! ",
                      img: uiux,
                      hoverimg:whiteuiux,
                      path: "/ui-ux-designing"
                    },
                    ,
                    {
                      id: 7,
                      title: "Graphic Designing",
                      desc: "Transform your ideas into captivating visuals with our expert Graphic Designing services – your brand's story, beautifully told.",
                      img: graphicdesi,
                      hoverimg:whitegraphic,
                      path: "/graphic-designing"
                    },
                    ,
                    {
                      id: 8,
                      title: "Social Media Marketing",
                      desc: "Transform your brand's online presence with our expert Social Media Marketing services - boosting engagement, reach, and growth like never before!",
                      img: Devopsimg,
                      hoverimg:whitedevops,
                      path: "/social-media-marketing"
             
                    },
                    {
                      id: 9,
                      title: "Search Engine Optimization",
                      desc: "Unlock your website's full potential with our expert Search Engine Optimization services and soar to the top of search results!",
                      img: Devopsimg,
                      hoverimg:whitedevops,
                      path: "/search-engine-optimization"
             
                    },
                    {
                      id: 10,
                      title: "Mobile App Development",
                      desc: "Transform your ideas into reality with our expert Mobile App Development services. Let's build your app together!",
                      img: Devopsimg,
                      hoverimg:whitedevops,
                      path: "/mobile-app-development"
             
                    },
                  ].map((val) => {
                    return (
                      <StyledCol xs={24} sm={24} md={22} lg={11} xl={8} xxl={8} 
                      onMouseEnter={() => setHover(val?.id)}
  onMouseLeave={() => setHover(0)}
                      >
                        <Link to={val?.path}>
                          <Row justify={screens?.lg ? "end" : "center"}>
                            <Col
                              span={24}
                              style={{
                                paddingInline: "2rem",
                                paddingTop: "1rem",
                              }}
                            >
                              <Row>
                                <StyledImageLogo
                                  src={hover===val?.id ?val?.hoverimg  :val?.img}
                                  preview={false}
                                  style={{ width: 64, height: 64 }}
                                />
                                <CardHeading>{val?.title}</CardHeading>
                                <CardPara>{val?.desc}</CardPara>
                              </Row>
                            </Col>
                            <Col span={24}>
                              
                              <Row justify={"end"} align={"bottom"}>
                                <LinkBox>
                                  <StyledImage />
                                </LinkBox>
                              </Row>
                            </Col>
                          </Row>
                        </Link>
                      </StyledCol>
                    );
                  })}
                 
                </Row>
              </Col>
             
            </Row>
          </Col>
        </Row>
      </Wrapper>
    </>
  );
};

export default LatestService;
