import React, { useEffect, useState } from "react";

// Image for web Development/////////////////////////////////////////////////////////////////////////////////////////////
import icon_wordpress from "../../assets/images/Nav icons/Dress Your Business/Web Dev/wordpress_development.png";
import icon_web_design from "../../assets/images/Nav icons/Dress Your Business/Web Dev/web_designing.png";
import icon_html_development from "../../assets/images/Nav icons/Dress Your Business/Web Dev/html_development.png";
import icon_microsites from "../../assets/images/Nav icons/Dress Your Business/Web Dev/microsites and landing pages.png";
import icon_content_management_system from "../../assets/images/Nav icons/Dress Your Business/Web Dev/contentmanagement_system.png";
// Image for Ecommerce
import icon_magento from "../../assets/images/Nav icons/Dress Your Business/Ecommerce/magento.png";
import icon_shopify from "../../assets/images/Nav icons/Dress Your Business/Ecommerce/shopify.png";
import icon_woo_commerce from "../../assets/images/Nav icons/Dress Your Business/Ecommerce/woo_commerce.png";
// Images for application development
import icon_custom_development from "../../assets/images/Nav icons/Dress Your Business/Application Dev/customsoftware_development.png";
import icon_open_source_development from "../../assets/images/Nav icons/Dress Your Business/Application Dev/opensorce_development.png";
//Images Hosted Application Development
import icon_hosted_application_development from "../../assets/images/Nav icons/Dress Your Business/Hosted apps/hostedapplication_development.png";
// Images for Mobile development
import icon_ios_development from "../../assets/images/Nav icons/Dress Your Business/Mob Dev/ios_development.png";
import icon_android_development from "../../assets/images/Nav icons/Dress Your Business/Mob Dev/android_development.png";
//Images for Web Service and AMc
import icon_web_amc from "../../assets/images/Nav icons/Dress Your Business/Web service & amc/web_services.png";
///////////////////////////////////////////////////////////////////////////////////////////////////////////////
import icon_product_designing from "../../assets/images/Nav icons/Composed Business Lingo/Graphic Designing/product_designing.png";
import icon_brochure_designing from "../../assets/images/Nav icons/Composed Business Lingo/Graphic Designing/brochure_designing.png";
import icon_business_card_designing from "../../assets/images/Nav icons/Composed Business Lingo/Graphic Designing/businesscard_designing.png";
import icon_corporate_designing from "../../assets/images/Nav icons/Composed Business Lingo/Graphic Designing/corporate_designing.png";
import icon_logo_designing from "../../assets/images/Nav icons/Composed Business Lingo/Graphic Designing/logo_designing.png";
import icon_ply from "../../assets/images/Nav icons/Composed Business Lingo/Graphic Designing/ply_designing.png";
import icon_editing from "../../assets/images/Nav icons/Composed Business Lingo/Video & Animation/Editing.png";
import icon_spokesperson from "../../assets/images/Nav icons/Composed Business Lingo/Video & Animation/Spokesperson_video.png";
import icon_promotional_video from "../../assets/images/Nav icons/Composed Business Lingo/Video & Animation/promotiotional_video.png";
import icon_short_video from "../../assets/images/Nav icons/Composed Business Lingo/Video & Animation/short_video_add.png";
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import icon_seo from "../../assets/images/Nav icons/Set Marketing Tone/Digital Marketing/Seo.png";
import icon_blog_creation_promotion from "../../assets/images/Nav icons/Set Marketing Tone/Digital Marketing/blog_creation_promotion.png";
import icon_contract_marketing from "../../assets/images/Nav icons/Set Marketing Tone/Digital Marketing/contract_marketing.png";
import icon_email_marketing from "../../assets/images/Nav icons/Set Marketing Tone/Digital Marketing/email_marketing.png";
import icon_pay_per_click_marketing from "../../assets/images/Nav icons/Set Marketing Tone/Digital Marketing/pay_per_click_marketing.png";
import icon_social_media_marketing from "../../assets/images/Nav icons/Set Marketing Tone/Digital Marketing/social_media_marketing.png";
import icon_social_media_opt from "../../assets/images/Nav icons/Set Marketing Tone/Digital Marketing/social_media_opt.png";
import icon_google_ads from "../../assets/images/Nav icons/Set Marketing Tone/Ads Service/google_ads.png";
import icon_google_place_opt from "../../assets/images/Nav icons/Set Marketing Tone/Ads Service/google_place_opt.png";
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import icon_seo_writing from "../../assets/images/Nav icons/Magic With Words/Professional Content Writing/Seo_writing.png";
import icon_article_web_and_blogpost from "../../assets/images/Nav icons/Magic With Words/Professional Content Writing/article_web_blogpost.png";
import icon_creative_writing from "../../assets/images/Nav icons/Magic With Words/Professional Content Writing/creative_writing.png";
import icon_business_copy_writing from "../../assets/images/Nav icons/Magic With Words/Legal & technical/business_copywriting.png";
import icon_transcription from "../../assets/images/Nav icons/Magic With Words/Legal & technical/transcription.png";

import { Menu, Row, Col, Grid, Image } from "antd";
import { Link, useNavigate } from "react-router-dom";
import LogoBlue from "../../assets/images/newlogo.png";
import LogoWhite from "../../assets/images/logo.png";
import styled from "styled-components";
import { Colors } from "../theme/theme";
import MobileNavbar from "../../common/MobileNavbar";
import { FaAlignJustify } from "react-icons/fa";

import { StyledLinkContainer } from "../GlobalStyles/global";
import { BoldHeading } from "../GlobalStyles/global";
import { Title } from "../GlobalStyles/global";
import { SubTitle } from "../GlobalStyles/global";
import { SubTitleLeft } from "../GlobalStyles/global";
import { MiniMenu } from "../GlobalStyles/global";

import "./style.css";

const { useBreakpoint } = Grid;

var icond_shadow =
  "rgba(0, 0, 0, 0.24) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset";

const Navbar = ({ temp }) => {
  const path = window.location.pathname;
  const screens = useBreakpoint();
  const [navBarColor, setNavBarColor] = useState(
    path == "/" || path == "/home" ? "transparent" : "white"
  );
  const [navBarTextColor, setNavBarTextColor] = useState(
    path == "/" || path == "/home" ? "white" : "rgb(1, 73, 124)"
  );
  const [navBarLogo, setNavBarLogo] = useState(
    path == "/" || path == "/home" ? LogoWhite : LogoBlue
  );
  const [current, setCurrent] = useState({
    id: 1,
    title: "Dress Your business",
    subdata: [
      {
        id: "web-dev",
        subtitle: "Web Development",
        path: "/web-development",
        arr: [
          {
            id: "web-desing",
            title: "Website Designing",
            path: "/web-designing",
            icon: icon_web_design,
          },

          {
            id: "wordpress-dev",
            title: "WordPress Development",
            path: "/wordpress-development",
            icon: icon_wordpress,
          },
          {
            id: "html-dev",
            title: "HTML5 Web Development",
            path: "/html5-web-development",
            icon: icon_html_development,
          },
          {
            id: "micro-site",
            title: "Micro Sites And Landing Pages",
            path: "/microsites-and-landing-pages",
            icon: icon_microsites,
          },
          {
            id: "cont-management",
            title: "Content Management System",
            path: "/content-management-system",
            icon: icon_content_management_system,
          },
          {
            id: "web-main-service",
            title: "Website Mantainance Services & AMC",
            path: "/web-mentainance-services-and-amc",
            icon: icon_web_amc,
          },
        ],
      },
      {
        id: "e-com",
        subtitle: "Ecommerce Development",
        path: "/ecommerce-development",
        arr: [
          {
            id: "magento-dev",
            title: "Magento Development",
            path: "/magento-development",
            icon: icon_magento,
          },
          {
            id: "shopify-dev",
            title: "Shopify Development",
            path: "/shopify-development",
            icon: icon_shopify,
          },
          {
            id: "woo-commerce",
            title: "Woo Commerce Development",
            path: "/woo-commerce-development",
            icon: icon_woo_commerce,
          },
        ],
      },
      {
        id: 1_3,
        subtitle: "Software Development",
        path: "/software-development",
        arr: [
          {
            id: "custom-soft-dev",
            title: "Custom Software Development",
            path: "/custom-software-development",
            icon: icon_custom_development,
          },
          {
            id: "open-source-dev",
            title: "Open Source Development",
            path: "/open-source-development",
            icon: icon_open_source_development,
          },
        ],
      },

      {
        id: 1_4,
        subtitle: "Mobile App Development",
        path: "/mobile-app-development",
        arr: [
          {
            id: "ios-dev",
            title: "IOS App Development",
            path: "/ios-app-development",
            icon: icon_ios_development,
          },
          {
            id: "android-dev",
            title: "Android App Development",
            path: "/android-app-development",
            icon: icon_android_development,
          },
        ],
      },
      {
        id: 1_5,
        subtitle: "Hosted Application Development",
        path: "/hosted-web-development",
        arr: [
          {
            id: "hosted-app-dev",
            title: "Hosted Application Development",
            path: "/hosted-application-development",
            icon: icon_hosted_application_development,
          },
        ],
      },
    ],
  });
  const [isHovered, setIsHovered] = useState(false);
  let enterpriseDropdownRef;

  const handleEnterpriseMouseEnter = () => {
    enterpriseDropdownRef.style.display = "block";
  };

  const handleEnterpriseMouseLeave = () => {
    enterpriseDropdownRef.style.display = "none";
    setIsHovered(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (path == "/" || path == "/home") {
        if (window.scrollY >= 180) {
          setNavBarColor("white");
          setNavBarLogo(LogoBlue);
          setNavBarTextColor("rgb(1, 73, 124)");
        } else {
          setNavBarLogo(LogoWhite);
          setNavBarColor("transparent");
          setNavBarTextColor("white");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log(current);
  return (
    <>
      <Menu
        mode="horizontal"
        style={{
          display: screens?.lg ? "flex" : "none",
          alignItems: "center",
          height: "90px",
          margin: 0,
          backgroundColor: navBarColor,
          paddingInline: "1rem",
          backdropFilter: "blur(7px)",
          transition: "background-color 0.5s",
        }}
      >
        <Link to="/" style={{ margin: 0, padding: "30px", marginTop: "1rem" }}>
          <img src={navBarLogo} alt="Logo" style={{ height: 80 }} />
        </Link>

        <Row
          style={{ height: "100%", marginLeft: "auto", textAlign: "center" }}
        >
          <StyledLinkContainer>
            <Link to="/home" onMouseEnter={handleEnterpriseMouseLeave}>
              <BoldHeading style={{ color: navBarTextColor }}>Home</BoldHeading>
            </Link>
          </StyledLinkContainer>
          <StyledLinkContainer>
            <Link
              to="/about-the-company"
              onMouseEnter={handleEnterpriseMouseLeave}
            >
              <BoldHeading style={{ color: navBarTextColor }}>
                Enterprise
              </BoldHeading>
            </Link>
          </StyledLinkContainer>
          <StyledLinkContainer
            bg={isHovered ? "rgb(240, 242, 245)" : "transparent"}
            onMouseEnter={() => setIsHovered(true)}
          >
            <Link to="" onMouseEnter={handleEnterpriseMouseEnter}>
              <BoldHeading
                style={{
                  color: isHovered ? "rgb(1, 73, 124)" : navBarTextColor,
                }}
              >
                Dress your business
              </BoldHeading>
            </Link>
          </StyledLinkContainer>
          <StyledLinkContainer>
            <Link to="/career" onMouseEnter={handleEnterpriseMouseLeave}>
              <BoldHeading style={{ color: navBarTextColor }}>
                Become partner
              </BoldHeading>
            </Link>
          </StyledLinkContainer>
          <StyledLinkContainer>
            <Link to="/contact-us" onMouseEnter={handleEnterpriseMouseLeave}>
              <BoldHeading style={{ color: navBarTextColor }}>
                Knock & Ringing
              </BoldHeading>
            </Link>
          </StyledLinkContainer>
        </Row>
      </Menu>
      <div
        ref={(ref) => (enterpriseDropdownRef = ref)}
        style={{
          position: "absolute",
          top: "100%",
          left: 0,
          display: "none",
          backgroundColor: "#f0f2f5",
          padding: "1rem",
          width: "100%",
        }}
      >
        <Row>
          <Col
            span={6}
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              alignContent: "flex-start",
              justifyItems: "flex-start",
              alignSelf: "flex-start",
              justifySelf: "flex-start",
            }}
          >
            <Row>
              {[
                {
                  id: 1,
                  title: "Dress Your business",
                  subdata: [
                    {
                      id: "web-dev",
                      subtitle: "Web Development ",
                      path: "/web-development",
                      arr: [
                        {
                          id: "web-desing",
                          title: "Webiste Designing",
                          path: "/web-designing",
                          icon: icon_web_design,
                        },

                        {
                          id: "wordpress-dev",
                          title: "WordPress Development",
                          path: "/wordpressdevelopment",
                          icon: icon_wordpress,
                        },
                        {
                          id: "html-dev",
                          title: "HTML5 Web Development",
                          path: "/html5-web-development",
                          icon: icon_html_development,
                        },
                        {
                          id: "micro-site",
                          title: "Micro Sites And Landing Pages",
                          path: "/microsites-and-landing-pages",
                          icon: icon_microsites,
                        },
                        {
                          id: "cont-management",
                          title: "Content Management System",
                          path: "/content-management-system",
                          icon: icon_content_management_system,
                        },
                        {
                          id: "web-main-service",
                          title: "Website Mantainance Services & AMC",
                          path: "/web-mentainance-services-and-amc",
                          icon: icon_web_amc,
                        },
                      ],
                    },
                    {
                      id: "e-com",
                      subtitle: "Ecommerce Development ",
                      path: "/ecommerce-development",
                      arr: [
                        {
                          id: "magento-dev",
                          title: "Magento Development",
                          path: "/magento-development",
                          icon: icon_magento,
                        },
                        {
                          id: "shopify-dev",
                          title: "Shopify Development",
                          path: "/shopify-development",
                          icon: icon_shopify,
                        },
                        {
                          id: "woo-commerce",
                          title: "Woo Commerce Development",
                          path: "/woo-commerce-development",
                          icon: icon_woo_commerce,
                        },
                      ],
                    },

                    {
                      id: "app-dev",
                      subtitle: "Software Development",
                      path: "/software-development",
                      arr: [
                        {
                          id: "custom-soft-dev",
                          title: "Custom Software Development",
                          path: "/custom-software-development",
                          icon: icon_custom_development,
                        },
                        {
                          id: "open-source-dev",
                          title: "Open Source Development",
                          path: "open-source-development",
                          icon: icon_open_source_development,
                        },
                      ],
                    },

                    {
                      id: "host-app",
                      subtitle: "Hosted Application Development",
                      path: "/hosted-application-development",

                      arr: [
                        {
                          id: "hosted-web-dev",
                          title: "Hosted Web Development",
                          path: "/hosted-web-development",
                          icon: icon_hosted_application_development,
                        },
                      ],
                    },
                    {
                      id: "mob-dev",
                      subtitle: "Mobile App Development",
                      path: "/mobile-app-development",
                      arr: [
                        {
                          id: "ios-dev",
                          title: "IOS App Development",
                          path: "/ios-app-development",
                          icon: icon_ios_development,
                        },
                        {
                          id: "android-dev",
                          title: "Android App Development",
                          path: "android-app-development",
                          icon: icon_android_development,
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 2,
                  title: "Compose Business Lingo",
                  subdata: [
                    {
                      id: "graphic-work",
                      subtitle: "Graphic Designing",
                      path: "/graphic-designing",

                      arr: [
                        {
                          id: "product-branding",
                          title: "Product Designing",
                          path: "/product-designing",
                          icon: icon_product_designing,
                        },
                        {
                          id: "corporate-branding",
                          title: "Corporate Designing",
                          path: "/corporate-designing",
                          icon: icon_corporate_designing,
                        },
                        {
                          id: "logo-designing",
                          title: "Logo Designing",
                          path: "/logo-designing",
                          icon: icon_logo_designing,
                        },
                        {
                          id: "brochure-design",
                          title: "Brochure Designing",
                          path: "/brochure-designing",
                          icon: icon_brochure_designing,
                        },
                        {
                          id: "business-card-design",
                          title: "Business Card Designing",
                          path: "/business-card-designing",
                          icon: icon_business_card_designing,
                        },
                        {
                          id: "fly-designing",
                          title: "Flyer Designing",
                          path: "/flyer-designing",
                          icon: icon_ply,
                        },
                      ],
                    },
                    {
                      id: "video-and-animation",
                      subtitle: "Video & Animation",
                      path: "/video-animation",

                      arr: [
                        {
                          id: "promotional-video",
                          title: "Promotional Video",
                          path: "/promotional-video",
                          icon: icon_promotional_video,
                        },
                        {
                          id: "edit-and-post-production",
                          title: "Editing & Post Prodution",
                          path: "/editing-and-post-production",
                          icon: icon_editing,
                        },
                        {
                          id: "spokesperson-video",
                          title: "Spokesperson Video",
                          path: "/spokesperson-video",
                          icon: icon_spokesperson,
                        },
                        {
                          id: "short-video-ads",
                          title: "Short Video Ads",
                          path: "/short-video-ads",
                          icon: icon_short_video,
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 3,
                  title: "Set Marketing Tone",
                  subdata: [
                    {
                      id: "digital-marketing",
                      subtitle: "Digital Marketing",
                      path: "/digital-marketing",

                      arr: [
                        {
                          id: "seo-work",
                          title: "Search Engine Optimiation",
                          path: "/search-engine-optimization",
                          icon: icon_seo,
                        },
                        {
                          id: "social-media-opt",
                          title: "Social Media Optimiation",
                          path: "/social-media-optimization",
                          icon: icon_social_media_opt,
                        },
                        {
                          id: "social-media-mark",
                          title: "Social Media Marketing",
                          path: "/social-media-marketing",
                          icon: icon_social_media_marketing,
                        },
                        {
                          id: "pay-per-click",
                          title: "Pay Per Click Marketing",
                          path: "/pay-per-click-marketing",
                          icon: icon_pay_per_click_marketing,
                        },
                        {
                          id: "blog-promotion",
                          title: "Blog Creation and Promotion",
                          path: "/blog-creation-and-promotion",
                          icon: icon_blog_creation_promotion,
                        },
                        {
                          id: "contract-marketing",
                          title: "Content Marketing",
                          path: "/contract-marketing", // contract marketing is content Marketing
                          icon: icon_contract_marketing,
                        },
                        {
                          id: "email-marketing",
                          title: "Email Marketing",
                          path: "/email-marketing",
                          icon: icon_email_marketing,
                        },
                        {
                          id: "ui-ux",
                          title: "UI/UX Designing",
                          path: "/ui-ux-designing",
                          icon: icon_pay_per_click_marketing,
                        },
                      ],
                    },
                    {
                      id: "advertising-service",
                      subtitle: "Advertising Service",
                      path: "/advertising-service",
                      arr: [
                        {
                          id: "google-ads-word",
                          title: "Google Ads and Bing Ads",
                          path: "/google-ads-and-bing-ads",
                          icon: icon_google_ads,
                        },
                        {
                          id: "goolge-place-opt",
                          title: "Google My Business",
                          path: "/google-my-business",
                          icon: icon_google_place_opt,
                        },
                      ],
                    },
                    // {
                    //   id: "Email Marketing",
                    //   subtitle: "email-marketing",
                    //   arr: [
                    //     {
                    //       id: "email-marke",
                    //       title: "Email Marketing",
                    //       path: "/email-marketing",
                    //     },
                    //   ],
                    // },
                  ],
                },
                {
                  id: 4,
                  title: "Magic with words",
                  subdata: [
                    {
                      id: "prof-cont-marketing",
                      subtitle: "Professional Content Writing",
                      path: "/professional-content-writing",
                      arr: [
                        {
                          id: "article-blog",
                          title: "Web Article Writing",
                          path: "/web-article-writing", //Fix
                          icon: icon_article_web_and_blogpost,
                        },
                        {
                          id: "seo-based-cont",
                          title: "SEO Based Content Writing",
                          path: "/seo-based-content-writing", //Fix
                          icon: icon_seo_writing,
                        },
                        {
                          id: "creative-writ",
                          title: "Creative Writing",
                          path: "/creative-writing", // Fix
                          icon: icon_creative_writing,
                        },
                      ],
                    },
                    {
                      id: "legal-technical",
                      subtitle: "Legal & Technical Writing",
                      path: "/itlegal-and-technical-writing",
                      arr: [
                        {
                          id: "business-copy",
                          title: "Copywriting",
                          path: "/copywriting",
                          icon: icon_business_copy_writing,
                        },

                        {
                          id: "transcrip",
                          title: "Transcription",
                          path: "/transcription",
                          icon: icon_transcription,
                        },
                      ],
                    },
                  ],
                },
              ].map((val) => {
                return (
                  <>
                    <Col
                      key={val.id}
                      span={24}
                      onMouseEnter={() => setCurrent(val)}
                    >
                      <Row>
                        <Col>
                          <SubTitleLeft
                            style={{
                              backgroundColor:
                                current?.id === val?.id ? "#01497c" : "",
                              color: current?.id === val?.id ? "white" : "",
                            }}
                          >
                            {val?.title}
                          </SubTitleLeft>
                        </Col>
                      </Row>
                    </Col>
                  </>
                );
              })}
            </Row>
          </Col>
          <Col
            span={18}
            style={{ padding: "2rem", borderLeft: "1px solid #333" }}
          >
            <Row>
              <Col span={24}>
                {current?.id === 1 ? (
                  <Row>
                    <Col span={24}>
                      <Col span={24}>
                        <Title>{current?.title}</Title>
                      </Col>
                      <Row>
                        {current?.subdata?.map((x) => {
                          return (
                            <Col key={x?.id} span={12}>
                              <Row
                                span={2}
                                style={{ marginBottom: "20px" }}
                                className={
                                  x.id === "web-main"
                                    ? "special-class"
                                    : "normal-class"
                                }
                              >
                                <Col span={24} style={{ fontWeight: "bold" }}>
                                  <Link to={x?.path}>
                                    <SubTitle>{x?.subtitle}</SubTitle>
                                  </Link>
                                </Col>
                                {x?.arr?.map((temp) => {
                                  return (
                                    <Col key={temp?.id} span={24}>
                                      <Link to={temp?.path}>
                                        <Image
                                          src={temp?.icon}
                                          width={24}
                                          height={24}
                                          preview={false}
                                          style={{
                                            padding: "5px",
                                            marginRight: "20px",
                                            boxShadow: icond_shadow,
                                            borderRadius: "25%",
                                          }}
                                        ></Image>
                                        <MiniMenu>{temp?.title}</MiniMenu>
                                      </Link>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                  </Row>
                ) : current?.id === 2 ? (
                  <>
                    <Row>
                      <Col span={24}>
                        <Col span={24}>
                          <Title>{current?.title}</Title>
                        </Col>
                        <Row>
                          {current?.subdata?.map((x) => {
                            return (
                              <Col key={x?.id} span={12}>
                                <Row>
                                  <Col span={24} style={{ fontWeight: "bold" }}>
                                    <Link to={x?.path}>
                                      <SubTitle>{x?.subtitle}</SubTitle>
                                    </Link>
                                  </Col>
                                  {x?.arr?.map((temp) => {
                                    return (
                                      <Col key={temp?.id} span={24}>
                                        <Link to={temp?.path}>
                                          <Image
                                            src={temp?.icon}
                                            width={24}
                                            height={24}
                                            preview={false}
                                            style={{
                                              padding: "5px",
                                              marginRight: "20px",
                                              boxShadow: icond_shadow,
                                              borderRadius: "25%",
                                            }}
                                          ></Image>
                                          <MiniMenu
                                            style={{ alignItems: "start" }}
                                          >
                                            {temp?.title}
                                          </MiniMenu>
                                        </Link>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                    </Row>
                  </>
                ) : current?.id === 3 ? (
                  <Row>
                    <Col span={24}>
                      <Col span={24}>
                        <Title>{current?.title}</Title>
                      </Col>
                      <Row>
                        {current?.subdata?.map((x) => {
                          return (
                            <Col key={x?.id} span={12}>
                              <Row>
                                <Col span={24} style={{ fontWeight: "bold" }}>
                                  <Link to={x?.path}>
                                    <SubTitle>{x?.subtitle}</SubTitle>{" "}
                                  </Link>
                                </Col>
                                {x?.arr?.map((temp) => {
                                  return (
                                    <Col key={temp?.id} span={24}>
                                      <Link to={temp?.path}>
                                        <Image
                                          src={temp?.icon}
                                          width={24}
                                          height={24}
                                          preview={false}
                                          style={{
                                            padding: "5px",
                                            marginRight: "20px",
                                            boxShadow: icond_shadow,
                                            borderRadius: "25%",
                                          }}
                                        ></Image>
                                        <MiniMenu
                                          style={{ alignItems: "start" }}
                                        >
                                          {temp?.title}
                                        </MiniMenu>
                                      </Link>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                  </Row>
                ) : current?.id === 4 ? (
                  <Row>
                    <Col span={24}>
                      <Col span={24}>
                        <Title>{current?.title}</Title>
                      </Col>
                      <Row>
                        {current?.subdata?.map((x) => {
                          return (
                            <Col key={x?.id} span={12}>
                              <Row>
                                <Col span={24} style={{ fontWeight: "bold" }}>
                                  {/* <SubTitle>{x?.subtitle}</SubTitle>{" "} */}
                                  <Link to={x?.path}>
                                    <SubTitle>{x?.subtitle}</SubTitle>{" "}
                                  </Link>
                                </Col>
                                {x?.arr?.map((temp) => {
                                  return (
                                    <Col key={temp?.id} span={24}>
                                      <Link to={temp?.path}>
                                        <Image
                                          src={temp?.icon}
                                          width={24}
                                          height={24}
                                          preview={false}
                                          style={{
                                            padding: "5px",
                                            marginRight: "20px",
                                            boxShadow: icond_shadow,
                                            borderRadius: "25%",
                                          }}
                                        ></Image>
                                        <MiniMenu
                                          style={{ alignItems: "start" }}
                                        >
                                          {temp?.title}
                                        </MiniMenu>
                                      </Link>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div style={{ display: screens?.lg ? "none" : "block" }}>
        <MobileNavbar temp={temp} />
      </div>
    </>
  );
};

export default Navbar;
