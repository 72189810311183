import {
  Layout,
  Row,
  Col,
  Typography,
  Input,
  Image,
  Avatar,
  Divider,
} from "antd";

import PkAddress from "./PkAdress";
import UaeAddress from "./UaeAddress";
import UsaAddress from "./UsAddress";

import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  TwitterCircleFilled,
  YoutubeFilled,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import phoneimg from "../../assets/images/phone-icon.gif";
import mapimg from "../../assets/images/mapicon.gif";
import emailimg from "../../assets/images/emailicon.gif";
import Connectbg from "../../assets/images/Home/footerbg.png";

import styled from "styled-components";
const { Footer } = Layout;
const BoldHeading = styled.div`
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  color: white;
  font-family: "Teko", sans-serif;
`;
const SubHeading = styled.div`
  font-size: 18px;
  font-weight: 500;
  font-family: "Teko", sans-serif;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-inline: 0.5rem;
  color: white;
`;

const StyledFooter = styled(Footer)`
  background-image: url(${Connectbg});
  background-size: cover;
`;
const Offices = styled.div`
  display: flex;
  height: max-content;
  justify-content: space-around;

  @media (max-width: 787px) {
    flex-wrap: wrap;
    justify-content: start;
    gap: 10px;
  }
`;

const AppFooter = () => {
  return (
    <StyledFooter>
      <Row justify={"center"} align={"middle"}>
        <Col span={24}>
          <Row justify={"start"} align={"middle"}>
            <Col xs={0} sm={8} md={8} lg={8} xl={8} xxl={8}>
              <Row justify={"center"} align={"middle"}>
                <Col>
                  <Image src={mapimg} height={64} width={64} preview={false} />
                </Col>
                <Col>
                  <BoldHeading>Find Us</BoldHeading>
                  <SubHeading>Dubai , UAE</SubHeading>
                </Col>{" "}
              </Row>
            </Col>
            <Col
              xs={24}
              sm={0}
              md={0}
              lg={0}
              xl={0}
              xxl={0}
              style={{ marginTop: "1rem" }}
            >
              <Row justify={"start"} align={"middle"}>
                <Col>
                  <Image src={mapimg} height={64} width={64} preview={false} />
                </Col>
                <Col>
                  <BoldHeading>Find Us</BoldHeading>
                  <SubHeading>Dubai , UAE</SubHeading>
                </Col>{" "}
              </Row>
            </Col>
            <Col xs={0} sm={8} md={8} lg={8} xl={8} xxl={8}>
              {" "}
              <Row justify={"center"} align={"middle"}>
                <Col>
                  <Image
                    src={phoneimg}
                    height={64}
                    width={64}
                    preview={false}
                  />
                </Col>
                <Col>
                  <BoldHeading>Call Us</BoldHeading>
                  <SubHeading>+92 335 3699465</SubHeading>
                </Col>{" "}
              </Row>
            </Col>
            <Col
              xs={24}
              sm={0}
              md={0}
              lg={0}
              xl={0}
              xxl={0}
              style={{ marginTop: "1rem" }}
            >
              {" "}
              <Row justify={"start"} align={"middle"}>
                <Col>
                  <Image
                    src={phoneimg}
                    height={64}
                    width={64}
                    preview={false}
                  />
                </Col>
                <Col>
                  <BoldHeading>Call Us</BoldHeading>
                  <SubHeading>+92 335 3699465</SubHeading>
                </Col>{" "}
              </Row>
            </Col>
            <Col xs={0} sm={8} md={8} lg={8} xl={8} xxl={8}>
              {" "}
              <Row justify={"center"} align={"middle"}>
                <Col>
                  <Image
                    src={emailimg}
                    height={64}
                    width={64}
                    preview={false}
                  />
                </Col>
                <Col>
                  <BoldHeading>Mail us</BoldHeading>
                  <SubHeading>info@macworldtechnology.com</SubHeading>
                </Col>{" "}
              </Row>
            </Col>
            <Col
              xs={24}
              sm={0}
              md={0}
              lg={0}
              xl={0}
              xxl={0}
              style={{ marginTop: "1rem" }}
            >
              {" "}
              <Row justify={"start"} align={"middle"}>
                <Col>
                  <Image
                    src={emailimg}
                    height={64}
                    width={64}
                    preview={false}
                  />
                </Col>
                <Col>
                  <BoldHeading>Mail us</BoldHeading>
                  <SubHeading>bilal@macworldgroup.com</SubHeading>
                </Col>{" "}
              </Row>
            </Col>
          </Row>
          <Divider style={{ borderColor: "white" }} />
        </Col>
        <Col span={24}>
          <Row justify={"start"}>
            <Col
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              xxl={8}
              style={{ marginTop: "1rem" }}
            >
              <Row>
                <Col span={22}>
                  <Row>
                    <Col span={24}>
                      <Image
                        src={Logo}
                        preview={false}
                        height={90}
                        style={{
                          maxWidth: "95%",
                          maxHeight: "95%",
                          objectFit: "cover",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col span={24}>
                      <SubHeading >
                      Mac World is a leading technology company specialized in different digital services. With a focus on innovation and exceptional customer experience, they offer a wide range of solutions including Digital Marketing, software, support, and consulting for individuals and businesses
                      </SubHeading>
                    </Col> */}
                    <Col span={24} style={{ marginBottom: "1rem" }}>
                      <BoldHeading
                        style={{ marginBottom: "0.5rem", marginTop: "2rem" }}
                      >
                        Follow us
                      </BoldHeading>
                      <div
                        style={{
                          width: "40px",
                          height: 2,
                          border: "2px solid #01497c",
                          marginBottom: "2rem",
                        }}
                      ></div>
                      <a
                        href="https://www.facebook.com/Macworldtechnologies"
                        target="_blank"
                      >
                        <Avatar
                          icon={<FacebookFilled style={{ color: "white" }} />}
                          size={40}
                          style={{
                            backgroundColor: "#01497C",
                            marginInline: "0.25rem",
                          }}
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/macworldtechnologies/"
                        target="_blank"
                      >
                        <Avatar
                          icon={<InstagramFilled style={{ color: "white" }} />}
                          size={40}
                          style={{
                            backgroundColor: "#6b4dbb",
                            marginInline: "0.25rem",
                          }}
                        />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/mac-world-technologies/"
                        target="_blank"
                      >
                        <Avatar
                          icon={<LinkedinFilled style={{ color: "white" }} />}
                          size={40}
                          style={{
                            backgroundColor: "#0073af",
                            marginInline: "0.25rem",
                          }}
                        />
                      </a>
                      <Avatar
                        icon={<YoutubeFilled style={{ color: "white" }} />}
                        size={40}
                        style={{
                          backgroundColor: "red",
                          marginInline: "0.25rem",
                        }}
                      />

                      <Avatar
                        icon={
                          <TwitterCircleFilled style={{ color: "white" }} />
                        }
                        size={40}
                        style={{ backgroundColor: "#1c9cea" }}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col></Col>
              </Row>
            </Col>

            <Offices style={{ marginTop: "1.5rem" }}>
              <UaeAddress />
              <PkAddress />
              <UsaAddress />
            </Offices>
          </Row>
        </Col>
      </Row>
    </StyledFooter>
  );
};

export default AppFooter;
