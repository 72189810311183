import React from "react";
import { Row, Col, Image, Button, Card } from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import AboutOne from "../../../assets/images/Become a partner/sucess.webp";
import AboutTwo from "../../../assets/images/Become a partner/power.webp";
import MicrositeBanner from "../../../assets/images/promotional.jpg";
import Webdesignicon from "../../../assets/images/ITSupport/webdesignicon.gif";

import Career6 from "../../../assets/images/career6.png";
import Navbar from "../../../common/Navbar";
import Wheregoing from "../Wheregoing";
import BannerComponent from "../../../common/BannerComponent";

import Call_kb from "../../../assets/images/Become Partner/KeyBenefits/Call Center.png";
import DigitalMarketing_kb from "../../../assets/images/Become Partner/KeyBenefits/Digital Marketing.png";
import graphic_kb from "../../../assets/images/Become Partner/KeyBenefits/Graphic Designing.png";
import development_kb from "../../../assets/images/Become Partner/KeyBenefits/Software Development.png";

const FixedComponent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 90px;
  background-color: transparent;
  z-index: 9999;
  border-bottom: 1px solid #808080;
  @media (max-width: 992px) {
    border-bottom: 0px solid #808080;
  }
`;
const StyleMetaDes = styled.div`
  text-align: left;
  font-family: "Teko", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #333;
`;

const StyleMeta = styled.div`
  text-align: left;
  font-family: "Teko", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #333;
`;
const StyledImageLogo = styled(Image)`
  width: 48;
  height: 48;
  margin-bottom: 2rem;
  background-color: #d1d0d0;
  border-radius: 30px;
`;

const BoldHeading = styled.div`
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: #333;
  font-family: "Teko", sans-serif;
`;
const SubHeading = styled.div`
  font-size: 18px;
  font-weight: 500;
  font-family: "Teko", sans-serif;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-inline: 0.5rem;
  color: #333;
  text-align: center;
`;

const StyledRow = styled(Row)`
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
`;
const StyleCard = styled(Card)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: auto;

  &:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);

    // ${StyledImageLogo} {
    //   background-color: #01497c;
    // }
  }
`;
const Banner = () => {
  return (
    <>
      <BannerComponent
        title={"Become Part of Our Team        "}
        desc={
          "Get professional assistance at Macworld and watch our promotional video experts translate your product or brand into a video that attracts and engages your audience.          "
        }
        imagepath={MicrositeBanner}
      />

      <Row style={{ marginTop: "5vh", marginBottom: "5vh" }}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          style={{ backgroundColor: "white" }}
        >
          <Row align={"middle"} style={{ height: "100%" }}>
            <Col span={24}>
              <Row
                style={{
                  backgroundColor: "#e1e1e1",
                  padding: "5rem",
                }}
              >
                <BoldHeading>Join Our Team of Experts</BoldHeading>
                <SubHeading>
                  At Macworld Technology, we are a globally dispersed team of
                  professionals working together to deliver innovative and
                  impactful digital solutions to tech brands and growing
                  businesses. Our team comprises strategists, researchers,
                  creatives, and engineers who bring unique perspectives and
                  experiences to the table.
                  <br />
                  <br />
                  We pride ourselves on fostering a strong remote culture that
                  values openness, learning, and collaboration. Our goal is to
                  create simple, useful, and scalable digital solutions that
                  exceed our clients' expectations.
                </SubHeading>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <StyledRow align={"middle"}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Col align={"middle"} style={{ height: "100%", padding: "1rem" }}>
                <SubHeading style={{ textAlign: "left", fontWeight: "bold" }}>
                  Unlock the Power of Collaboration: Join Our Partner Network
                  Today!
                </SubHeading>
                <SubHeading style={{ textAlign: "left" }}>
                  Discover endless possibilities by becoming a valued partner of
                  Mac World Technology, where innovation meets opportunity, and
                  together we shape the future of technology.
                </SubHeading>
              </Col>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              style={{ backgroundColor: "#4b4b4b" }}
            >
              <Image src={AboutOne} preview={false} />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              style={{ backgroundColor: "#01497c" }}
            >
              <Image src={AboutTwo} preview={false} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Row align={"middle"} style={{ height: "100%", padding: "1rem" }}>
                <SubHeading style={{ textAlign: "left", fontWeight: "bold" }}>
                  Accelerate Your Success: Partner with Mac World Technology!
                </SubHeading>
                <SubHeading style={{ textAlign: "left" }}>
                  Embrace a transformative journey and amplify your business
                  growth with Mac World Technology's comprehensive partner
                  services, empowering you to achieve new heights of success.
                </SubHeading>
              </Row>
            </Col>
          </StyledRow>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "5vh",

          marginBottom: "5vh",
          paddingInline: "5rem",
          paddingTop: "10vh",
          paddingBottom: "10vh",
        }}
      >
        <Col span={24}>
          <Row justify={"center"} style={{ marginBottom: "3rem" }}>
            <Col span={24}>
              <BoldHeading>Are you Ready?</BoldHeading>
            </Col>
            <Col span={24}>
              <SubHeading style={{ textAlign: "center" }}>
                Make your mark in the digital landscape by joining our
                Organization, where your creativity, expertise, and passion for
                technology will be nurtured and rewarded
              </SubHeading>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[20, 20]} justify={"center"}>
            {[
              {
                id: 1,
                title: "Software Development",
                desc: " Develop the most jaw-dropping Wordpress sites you’ve ever seen.",
                imgIcon: development_kb,
              },
              {
                id: 2,
                title: "Graphic Designing",
                desc: " Develop the most jaw-dropping Wordpress sites you’ve ever seen.",
                imgIcon: graphic_kb,
              },
              {
                id: 3,
                title: "Digital Marketing",
                desc: " Develop the most jaw-dropping Wordpress sites you’ve ever seen.",
                imgIcon: DigitalMarketing_kb,
              },
              {
                id: 4,
                title: "Call Center",
                desc: " Develop the most jaw-dropping Wordpress sites you’ve ever seen.",
                imgIcon: Call_kb,
              },
            ].map((val) => {
              return (
                <Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={6}>
                  <StyleCard hoverable>
                    <Row justify={"start"}>
                      <Col span={24}>
                        <StyledImageLogo
                          src={val?.imgIcon}
                          preview={false}
                          style={{ width: 48, height: 48, padding: "5px" }}
                        />
                      </Col>
                    </Row>
                    <Row justify={"start"}>
                      <StyleMeta>{val?.title}</StyleMeta>

                      <StyleMetaDes>{val?.desc}</StyleMetaDes>
                    </Row>
                  </StyleCard>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Banner;
