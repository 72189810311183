import React from "react";

import Banner from "../../components/CareerScreen/Banner";
import Opening from "../../components/CareerScreen/Opening";
import GuidingPrinciple from "../../components/CareerScreen/GuidingPrinciple";
import HomeGrown from "../../components/CareerScreen/HomeGrown";
import SingleBanner from "../../components/CareerScreen/SingleBanner";
import Gallery from "../../components/CareerScreen/Gallery";
const Careers = () => {
  return (
    <>
      <Banner />
      {/* <Gallery/> */}
      <SingleBanner/>
      {/* <Opening/> */}
    

{/* <GuidingPrinciple/>
<HomeGrown/> */}
    </>
  );
};

export default Careers;
