import React from "react";
import { Row, Col, Image, Grid } from "antd";
import styled, { keyframes } from "styled-components";

import { BoldHeadingQuote } from "../../../common/GlobalStyles/global";
import { SubHeading } from "../../../common/GlobalStyles/global";
import { WrapperProcess } from "../../../common/GlobalStyles/global";
import { CardDescProcess } from "../../../common/GlobalStyles/global";
import { CardTitleProcess } from "../../../common/GlobalStyles/global";
import { CardContentProcess } from "../../../common/GlobalStyles/global";
import { CardImgProcess } from "../../../common/GlobalStyles/global";
import { CardProcess } from "../../../common/GlobalStyles/global";

const ProcessMethadology = ({ title, description, CardData }) => {
    return (
        <WrapperProcess>
            <Row
                justify={"center"}
                style={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100% ",
                    flexWrap: "wrap",

                }}
            >
                <Col span={24}>
                    <Row justify={"center"}>
                        <Col span={20}>
                            <BoldHeadingQuote
                                style={{ color: "#333", paddingBottom: "20px" }}
                            >
                                {title
                                    .split(" ")
                                    .slice(0, title.split(" ").length - 1)
                                    .join(" ")}{" "}
                                <span style={{ color: "#01497c", paddingBottom: "20px" }}>
                                    {title.split(" ")[title.split(" ").length - 1]}
                                </span>
                            </BoldHeadingQuote>
                            <SubHeading
                                style={{ textAlign: "center", paddingBottom: "20px" }}
                            >
                                {description}
                            </SubHeading>
                        </Col>
                    </Row>
                    <Row
                        span={24}
                        style={{
                            marginTop: "50px",
                            padding: "30px 0px",
                            margin: "auto",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "40px",

                        }}
                        gutter={[0, 80]}
                        justify={"center"}
                    >
                        {CardData.map((e, i) => (
                            <Col xs={24} sm={12} md={10} lg={6} xl={4} xxl={4}>
                                <CardProcess>
                                    <CardImgProcess alt="Home Card Pic" src={e?.img} />
                                    <CardContentProcess className="card__content">
                                        <CardTitleProcess className="card__title">
                                            {e.title}
                                        </CardTitleProcess>
                                        <CardDescProcess className="card__description">
                                            {e.desc}
                                        </CardDescProcess>
                                    </CardContentProcess>
                                </CardProcess>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </WrapperProcess>
    );
};

export default ProcessMethadology;
