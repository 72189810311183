import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "antd";

import { InlineWidget} from "react-calendly";


import { BoldHeadingQuote } from "../../../common/GlobalStyles/global";
import { SubHeading } from "../../../common/GlobalStyles/global";
import { MWButton } from "../../../common/GlobalStyles/global";
import { Calendar } from "../../../common/GlobalStyles/global";
import { StyledModal } from "../../../common/GlobalStyles/global";
import { Web } from "../../../common/GlobalStyles/global";














const GetQuote = ({title,description}) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  
 

 

 
  useEffect(() => {
    const handleEventScheduled = (event) => {
      // Handle event scheduled
    };

    const handleEventCanceled = (event) => {
      // Handle event canceled
    };

    window.addEventListener("message", handleEventScheduled);
    window.addEventListener("message", handleEventCanceled);

    return () => {
      window.removeEventListener("message", handleEventScheduled);
      window.removeEventListener("message", handleEventCanceled);
    };
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = (values) => {
    // Access the form values here
    const { firstname, position, companyname, email, phonenumber } = values;
    console.log("First Name:", firstname);
    console.log("Position:", position);
    console.log("email:", email);
    console.log("phonenumber:", phonenumber);

    console.log("Company Name:", companyname);
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({ ...prevData, [name]: value }));
  // };


  return (
    <>
      <Web >
        <Row
          align={"middle"}
          style={{ paddingTop: "5vh", paddingBottom: "5vh" }}
        >
          <Row justify={"center"} align={"middle"}>
            <Row
              justify={"center"}
              style={{ paddingBottom: "5vh", paddingTop: "5vh" }}
            >
              <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
                <Row justify={"center"}>
                  <Col span={24}>
                    <BoldHeadingQuote style={{ textAlign: "center" }}>
                      {title?title:"Get Started with Our Digital Marketing Services"}
                    </BoldHeadingQuote>
                  </Col>
                  <Col xs={22} sm={22} md={23} lg={16} xl={12} xxl={12}>
                    <SubHeading style={{ textAlign: "center" , color:"white"}}>
                      {description?description:"Are you prepared to grow your company to new heights?Contact us right away to get or digital marketing services and maximize your online visibility."}
                    </SubHeading>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Col xs={22} sm={22} md={20} lg={12} xl={12} xxl={12}>
              <Form
                form={form}
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
              >
                {/* <Row gutter={[20, 0]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item 
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Name",
                        },
                      ]}
                    >
                      <CustInput
                        name="name"
                        placeholder="Name"
                        onChange={handleInputChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Email Address",
                        },
                      ]}
                    >
                      <CustInput
                        name="email"
                        placeholder="Email Address"
                        onChange={handleInputChange}
                      />
                    </Form.Item>
                  </Col>
                </Row> */}

                {/* <Row gutter={[20,0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                      name="companyname"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Company Name",
                        },
                      ]}
                    >
                      <CustInput name="companyname" placeholder="Company Name"  onChange={handleInputChange}/>
                    </Form.Item>
              
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                      name="position"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Position",
                        },
                      ]}
                    >
                      <CustInput name="position" placeholder="Position"  onChange={handleInputChange}/>
                    </Form.Item>
              
              </Col>
            </Row> */}

                {/* second field */}

                {/* <Row justify={"center"}>
              <Col span={24}>
              <Form.Item
                      name="phonenumber"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Phone Number",
                        },
                      ]}
                    >
                      <CustInput name="phonenumber" placeholder="Phone Number"  onChange={handleInputChange}/>
                    </Form.Item>
               
              </Col>
            </Row>  */}
                {/* <Row justify={"center"}>
                
                  <Col span={24}>
                    <Form.Item
                      name="calendly"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Phone Number",
                        },
                      ]}
                    >
                      <CustInput
                        name="calendly"
                        placeholder="Schedule Data and Time"
                        onChange={handleInputChange}
                        
                      />
                    </Form.Item>
                  </Col>
                </Row> */}
                 <Row justify={"center"}>
                    <MWButton htmlType="submit" onClick={showModal}>Schedule Now !</MWButton>
                  </Row>
                <StyledModal
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <Row justify={"center"}>
                    <Col span={24}>
                      <Form.Item
                        name="calendly"
                        rules={[
                          {
                            required: true,
                            message: "Please select a date and time",
                          },
                        ]}
                      >
                        <Calendar>
                          <InlineWidget
                            // prefill={{
                            //   name: formData?.name,
                            //   email: formData?.email,
                            // }}
                            url={`https://calendly.com/info-mcworld/30min`}
                          />
                        </Calendar>
                      </Form.Item>
                    </Col>
                  </Row>
                </StyledModal>

                <Col span={24}>
                 
                </Col>
              </Form>
            </Col>
          </Row>
        </Row>
      </Web>
    </>
  );
};

export default GetQuote;
