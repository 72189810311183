import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "antd";



import { BoldHeadingQuote } from "../../../common/GlobalStyles/global";
import { SubHeading } from "../../../common/GlobalStyles/global";



const HeroText = ({ title, description }) => {
  return (
    <>

      <Row
        align={"middle"}
        style={{ paddingTop: "5vh", paddingBottom: "5vh" }}
      >
        <Row justify={"center"} align={"middle"}>
          <Row
            justify={"center"}
            style={{ paddingBottom: "5vh", paddingTop: "5vh" }}
          >
            <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
              <Row justify={"center"}>
                <Col span={24}>
                  <BoldHeadingQuote style={{ textAlign: "center", color: "black" }}>
                    {title ? title : "Get Started with Our Digital Marketing Services"}
                  </BoldHeadingQuote>
                </Col>
                <Col xs={22} sm={22} md={23} lg={16} xl={12} xxl={12}>
                  <SubHeading style={{ textAlign: "center", color: "black" }}>
                    {description ? description : "Are you prepared to grow your company to new heights?Contact us right away to get or digital marketing services and maximize your online visibility."}
                  </SubHeading>
                </Col>
              </Row>
            </Col>
          </Row>

        </Row>
      </Row>

    </>
  );
};

export default HeroText;
