import React from 'react'
import { Row, Col, Card, Image, List ,Button} from "antd";
import styled from "styled-components";
import Webdesignicon from "../../../assets/images/ITSupport/webdesignicon.gif";
import join_us from "../../../assets/images/Contact/expertsolution/Join us.png"
import office from "../../../assets/images/Contact/expertsolution/Office.png"
import social from "../../../assets/images/Contact/expertsolution/Social.png"

import { Colors } from '../../../common/theme/theme';
import { Link } from 'react-router-dom';
const StyledImageLogo = styled(Image)`
  width: 48;
  height: 48;
  margin-bottom: 2rem;
  background-color: #d1d0d0;
  border-radius: 30px;
`;
const StyleCard = styled(Card)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);

    // ${StyledImageLogo} {
    //   background-color: #01497c;
    // }
  }
`;
const Bullet = styled.p`
  font-size: 18px;
  font-family: "Teko", sans-serif;
  font-weight: 500;
  text-align: start;
  margin-bottom: 0px;
 
  color: #333;
`;
const BoldHeading = styled.div`
  text-align: left;
  font-size: 40px;
  font-weight: 600;
  color: #333;
  font-family: "Teko", sans-serif;
 
`;
const SubHeading = styled.div`
  font-size: 18px;
  font-weight: 500;
  font-family: "Teko", sans-serif;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-inline: 0.5rem;
  color: #333;
  text-align:left;
`;


const UnderLine = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #333;


  position: relative;
  font-family: "Teko", sans-serif;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: ${(props) => props.len};
    height: 2px;
    background-color: #B0B0B0;
    transform-origin: left;
    font-family: "Teko", sans-serif;

  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0rem;
    width: ${(props) => props.len};
    height: 2px;
    background-color:    #01497c;
    transition: transform 0.3s ease-in-out;
    transform: scaleX(0);
    transform-origin: left;
  }

  &:hover::after {
    transform: scaleX(1);
  }


 
`;
const MWButtonTwo = styled(Button)`
  && {
    background-color: transparent;
    border: 0;
    color: black;
    height: 40px;
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-inline: 3rem;
    border: 2px solid black;
    font-size:18px;
    padding-bottom:1px;
    &:hover {
      transform: translateY(-10px); 
      border: 2px solid ${Colors?.primary};
      color:${Colors?.primary};

    }
  }
`;

const Cards = () => {
  return (
    <Row
        style={{
          marginTop: "5vh",

          marginBottom: "5vh",
          paddingInline: "5rem",
          paddingTop: "10vh",
          paddingBottom: "10vh",
        }}
      >
        <Col span={24}>
          <Row justify={"center"} style={{ marginBottom: "3rem" }}>
            <BoldHeading>
              {" "}
              <span style={{ color: "#01497c" }}>Customer Support</span>
            </BoldHeading>
            <p style={{color: "black", fontSize: "2rem", textAlign: "center", paddingLeft:"15px"}}>Customers are first and foremost for every business. They are the lifeblood of a company, as without them, there is no business. We at Mac World Technology understand the importance of customers, so our customer service is our top priority, and we work really hard to provide you with the best help possible. You can get support by contacting us through various channels:</p>
          </Row>
        </Col>
        <Col span={24} >
          <Row gutter={[20, 20]} justify={"center"}>
            {[
               
              {
                id: 1,
                title: "Live Chat",
                desc: "We provide an online chat service through which you can talk to one of our representatives in real time about your issues.",
                // desc2:'Twitter: @macworldtechnologies',
                // desc3:'Instagram: @macworldtechnologies/',
                imgIcon:social,
                opt:0
              },
              {
                id: 2,
                title: "24/7 helpline",

                desc: "we offer you a helpline service that is available 24 hours a day, 7 days a week, so feel free to contact. ",
                // desc2:'New business, general and press. ',
                // desc3:'careers@macworldtechnology.com',
                // desc4:'If you wish to join our team',
                imgIcon:join_us,
                opt:1


              },
              {
                id: 3,
                title: "Support Ticket",
                desc: "By submitting a support ticket, you can be sure that your concern will be noted and addressed as soon as possible.",
            //  desc2:'Shahra e Faisal         ',
             imgIcon:office,
             opt:0


              },
             
            ].map((val) => {
              return (
                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                  <StyleCard hoverable>
                    <Row justify={"start"}>
                      <Col span={24}>
                        <StyledImageLogo
                          src={val?.imgIcon}
                          preview={false}
                          style={{ width: 48, height: 48 , padding:5}}
                        />
                      </Col>
                    </Row>
                    <Row justify={"start"}>
                      <Col span={24}>

                   
                    <SubHeading style={{textAlign:'left'}}>
              {" "}
              <UnderLine len={val?.title ? '70px':'0px'}>{val?.title}

</UnderLine>
</SubHeading>
</Col>

<Col span={24}>

<Bullet style={{marginBottom:'0.5rem'}}>{val?.desc}</Bullet>
</Col>
<Col span={24}>
<Bullet style={{marginBottom:'0.5rem'}}>{val?.desc2}</Bullet>
</Col>
<Col span={24}>
<Bullet style={{marginBottom:'0.5rem'}}>{val?.desc3}</Bullet>
</Col>
<Col span={24}>
<Bullet style={{marginBottom:'0.5rem'}}>{val?.desc4}</Bullet>
</Col>

{/* <Col span={24}>
<Link to="/career">

  <MWButtonTwo style={{display:val?.opt ===0?'none':'flex'}}>
    Join Us
  </MWButtonTwo>
  </Link>
</Col> */}
                    </Row>
                  </StyleCard>
                </Col>
                
              );
            })}
          </Row>
        </Col>
      </Row>
      
  )
}

export default Cards