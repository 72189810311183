import React from "react";
import { Row, Col, Image, Button } from "antd";
import Career1 from '../../../assets/images/career1.png'
import Career2 from '../../../assets/images/career2.png'

import Career3 from '../../../assets/images/career3.png'
import Career4 from '../../../assets/images/career4.png'
import Career5 from '../../../assets/images/career5.png'
import styled from "styled-components";

import Career6 from '../../../assets/images/career6.png'
const TeamTwoImg = styled(Image)`
  opacity: 0.9;

  padding: 2rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.01);
    opacity: 1;
  }
`;
const Gallery = () => {
  return (
    <Row style={{ backgroundColor: "#E2EBF5" }}>
      <Col span={24}>
        <Row style={{ marginTop: "75px" }} align={"middle"} justify={"center"}>
          <Col span={22} style={{ paddingTop: "5rem" }}>
          <Row
              align={"middle"}
              justify={"center"}
              style={{marginTop:'10rem',marginBottom:'10rem'}}
            >
              <Col span={24} >
                <Row align={"bottom"}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} >
                  <TeamTwoImg
                        objectfit="contain"
                        preview={false}
                        style={{ width: "100%",height:'550px'}}
                        src={Career1}
                        alt="forshippers"
                        width={"100%"}
                      />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <TeamTwoImg
                        objectfit="contain"
                        preview={false}
                        style={{ width: "100%",height:'640px'}}
                        src={Career2}
                        alt="forshippers"
                        width={"100%"}

                      />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <TeamTwoImg
                        objectfit="contain"
                        preview={false}
                        style={{ width: "100%",height:'500px'}}
                        src={Career3}
                        alt="forshippers"
                        width={"100%"}

                      />
                  </Col>
                </Row>
              </Col>
            </Row>

             {/* second row */}
             <Row
              align={"middle"}
              justify={"center"}
              style={{marginTop:'10rem',marginBottom:'10rem'}}
            >
              <Col span={24} >
                <Row align={"top"}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} >
                  <TeamTwoImg
                        objectfit="contain"
                        preview={false}
                        style={{ width: "100%",height:'500px'}}
                        src={Career4}
                        alt="forshippers"
                        width={"100%"}
                      />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <TeamTwoImg
                        objectfit="contain"
                        preview={false}
                        style={{ width: "100%",height:'640px'}}
                        src={Career5}
                        alt="forshippers"
                        width={"100%"}

                      />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <TeamTwoImg
                        objectfit="contain"
                        preview={false}
                        style={{ width: "100%",height:'550px'}}
                        src={Career6}
                        alt="forshippers"
                        width={"100%"}

                      />
                  </Col>
                </Row>
                </Col>
            </Row>



          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Gallery;
