import React from "react";
import { Row, Col, Image, Tag } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";

import AboutOne from "../../../assets/images/Home/Converted/brands.webp";
import AboutTwo from "../../../assets/images/Home/Converted/unique.webp";

import { QuoteDesc } from "../../../common/GlobalStyles/global";
import { BoldHeading } from "../../../common/GlobalStyles/global";
import { SubHeading } from "../../../common/GlobalStyles/global";
import { StyledRow } from "../../../common/GlobalStyles/global";

const BoostYourBusiness = () => {
  return (
    <>
      <Row style={{ marginTop: "5vh", marginBottom: "5vh" }}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          style={{ backgroundColor: "white" }}
        >
          <Row align={"middle"} style={{ height: "100%" }}>
            <Col span={24}>
              <Row
                style={{
                  backgroundColor: "#e1e1e1",
                  padding: "5rem",
                }}
              >
                <Col span={24}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "3rem",
                          height: 1,
                          border: "1px solid black",
                          width: "50px",
                          flexDirection: "row",
                          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                          marginRight: "2rem",
                        }}
                      />
                    </div>
                    <div>
                      <QuoteDesc style={{ marginTop: "2rem", color: "black" }}>
                        Join Us
                      </QuoteDesc>
                    </div>
                  </div>
                  <BoldHeading
                    style={{
                      color: "black",
                      fontSize: "2.5rem",
                      fontWeight: "600",
                    }}
                  >
                    Empowering Growth through Innovative IT Solutions
                  </BoldHeading>
                </Col>
                <SubHeading>
                  In today's fast-paced business landscape, Mac World Technology
                  serves as a catalyst for growth, propelling other businesses
                  towards success through cutting-edge solutions. By harnessing
                  the power of technology, Mac World Technology’s experts enable
                  companies to optimize their operations, streamline processes,
                  and make data-driven decisions. We equip businesses with the
                  tools needed to expand their reach, enhance customer
                  experiences, and stay ahead of competitors. We also offers
                  invaluable consultation, guiding organizations in leveraging
                  emerging trends such as AI, IoT, and big data to unlock new
                  opportunities.
                </SubHeading>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <StyledRow align={"middle"}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Col align={"middle"} style={{ height: "100%", padding: "1rem" }}>
                <SubHeading style={{ textAlign: "left", fontWeight: "bold" }}>
                  Why to be beyond competitors
                </SubHeading>
                <SubHeading style={{ textAlign: "left" }}>
                  {" "}
                  Being beyond competitors offers a vantage point of innovation
                  and excellence. It drives You to exceed boundaries, pioneer
                  new solutions, and set benchmarks. By transcending the norm,
                  we secure lasting success for you, and cultivate a unique
                  identity. Embracing this mindset propels us towards continuous
                  growth and leadership in our endeavors{" "}
                </SubHeading>
              </Col>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              style={{ backgroundColor: "#4b4b4b" }}
            >
              <Image src={AboutOne} preview={false} />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              style={{ backgroundColor: "#01497c" }}
            >
              <Image src={AboutTwo} preview={false} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Row align={"middle"} style={{ height: "100%", padding: "1rem" }}>
                <SubHeading style={{ textAlign: "left", fontWeight: "bold" }}>
                  Take your Brand to Billions Globally
                </SubHeading>
                <SubHeading style={{ textAlign: "left" }}>
                  {" "}
                  Transform your brand's reach with our expert strategies. From
                  local roots to global eminence, we propel your brand towards
                  billion-fold success. Unlock international markets, captivate
                  diverse audiences, and forge an indelible presence worldwide.
                  Elevate your brand's journey – from inception to billions –
                  and leave an enduring mark on the global stage.
                </SubHeading>
              </Row>
            </Col>
          </StyledRow>
        </Col>
      </Row>
    </>
  );
};

export default BoostYourBusiness;
