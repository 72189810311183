import React from "react";
import { Row, Col, Image, Tag } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";

import TeamOne from "../../../assets/images/Home/expertise.jpg";
import TeamTwo from "../../../assets/images/Home/team.jpg";

import { BoldHeadingQuote } from "../../../common/GlobalStyles/global";
import { SubHeading } from "../../../common/GlobalStyles/global";
import { StyledRow } from "../../../common/GlobalStyles/global";
import { QuoteDesc } from "../../../common/GlobalStyles/global";





const TeamExpertise = () => {
  return (
    <>
  
  <Row style={{ marginTop: "5vh", marginBottom: "5vh" }}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          style={{ backgroundColor: "white" }}
        >
          <Row align={"middle"} style={{ height: "100%" }}>
            <Col span={24}>
              <Row
                style={{
                  backgroundColor: "#e1e1e1",
                  padding: "5rem",
                }}
              >
                <Col span={24} >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "3rem",
                      height: 1,
                      border: "1px solid black",
                      width: "50px",
                      flexDirection: "row",
                      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                      marginRight: "2rem",
                    }}
                  />
                </div>
                <div>
                  <QuoteDesc style={{ marginTop: "2rem" ,color:'black'}}>
                  Explore Our Team

                  </QuoteDesc>
                </div>
              </div>
              <BoldHeadingQuote style={{ color: "black" }}>
              Team and Expertise
              </BoldHeadingQuote>
            </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <StyledRow align={"middle"}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Col align={"middle"} style={{ height: "100%", padding: "1rem" }}>
                <SubHeading style={{textAlign:'left'}}>
                  {" "}
                  Mac World Technology owes its success to its team of devoted employees. Our team's extensive capabilities and experience enable us to provide diverse technologies, partnerships, and certifications. We take great pride in our ability to offer exceptional services and solutions to meet our client's requirements.</SubHeading>
              </Col>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              style={{ backgroundColor: "#4b4b4b" }}
            >
              <Image src={TeamOne} preview={false} />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              style={{ backgroundColor: "#01497c" }}
            >
              <Image src={TeamTwo} preview={false} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Row align={"middle"} style={{ height: "100%", padding: "1rem" }}>
                <SubHeading style={{textAlign:'left'}}>
                  {" "}
                  We prioritize lifelong learning and stay up-to-date on advanced technology and market changes. This allows us to provide innovative and customized IT solutions to our clients. Our devoted team's commitment to professional development ensures that we are always at the forefront of the evolving IT landscape. Our offer
                </SubHeading>
              </Row>  
            </Col>
          </StyledRow>
        </Col>
      </Row>
    </>
  );
};

export default TeamExpertise;