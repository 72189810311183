import React, { useState, useEffect } from "react";
import { Row, Col, Input, Form, Select, Button } from "antd";
import styled from "styled-components";
const { Option } = Select;
const Web=styled.div`
margin-bottom: 10rem;
 @media (max-width: 768px) {
    display:none;
  }
`;
const Mobile=styled.div`
margin-bottom: 10rem;

 @media (min-width: 768px) {
    display:none;
  }
`;


const CustInput = styled(Input)`
  height: 55px;
  border: 0px solid #939393;
  width: 100%;
  background-color: #3c4043;
  border-radius: 0;
  width: 100%;
  &::placeholder {
    
    color: white; 
  }
`;
const CustInputTextArea = styled(Input.TextArea)`
  height: 100px !important;
  border: 0px solid #939393;

  background-color: #3c4043;
  border-radius: 0;
  width: 100%;
  padding: 1rem;
  &::placeholder {
    color: white;
  }
`;
const MWButton = styled(Button)`
  background-color: #01497c;
  color: white;
  font-family: "Teko", sans-serif !important;

  width: 200px;
  font-size: 16px;
  font-weight: bold;
  opacity: 1;
  border: 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 40px;

  margin-top: 2rem;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
    color: white !important;
    text-decoration: none;
  }
`;
const Para = styled.div`
  text-align: left;
  font-family: "Teko", sans-serif !important;
  font-size: 2rem;
  font-weight: 500;
  height: 55px;
  display: flex;
  align-items: center;
  color: #01497c !important;
`;
const BoldHeading = styled.div`
  text-align: left;
  font-size: 7rem;
  font-weight: 600;
  color: #333;

  font-family: "Teko", sans-serif;
  @media (max-width: 768px) {
    font-size: 6rem;
  }
`;
const SubHeading = styled.div`
  text-align: left;
  font-family: "Teko", sans-serif;
  font-size: 2rem;
  font-weight: 500;
  color: #333;

`;
const Opening = () => {
  const [form] = Form.useForm();


  const onFinish = () => {};

  
  return (
    <>
    
      <Row justify={"center"} style={{ marginBottom: "5rem",paddingTop:'5rem' }}>
        <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
          <Row>
            <Col xs={22} sm={22} md={16} lg={16} xl={12} xxl={12}>
              <BoldHeading
               
              >
               Current openings
              </BoldHeading>
              <SubHeading
              
              >
                Self-Developemnt, Progression, A Cohesive Work Enviroment and an Esteemed Organizational Culture!
                <br/><br/>
                Join the MacWorld Team and help redefine innovation and digitization.


              </SubHeading>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* form */}
    
      <Web>
      <Row justify={"center"}>
        <Col xs={22} sm={22} md={16} lg={12} xl={12} xxl={12}>
          <Form form={form} name="basic" onFinish={onFinish} autoComplete="off">
            <Row>
              <Col span={24}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div>
                    <Para style={{ marginRight: "1rem" }}>My Name is</Para>
                  </div>
                  <div style={{ flex: 1 }}>
                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your First Name",
                        },
                      ]}
                    >
                      <CustInput placeholder="Full Name" />
                    </Form.Item>
                  </div>
                  <div>
                    <Para style={{ justifyContent: "end", marginLeft: "1rem" }}>
                      and I work{" "}
                    </Para>
                  </div>
                </div>
              </Col>
            </Row>
            {/* second field */}

            <Row justify={"center"}>
              <Col span={24}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div>
                    <Para style={{ marginRight: "1rem" }}>with</Para>
                  </div>
                  <div style={{ flex: 1 }}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input yourEmail Address",
                        },
                      ]}
                    >
                      <CustInput placeholder="Email Address" />
                    </Form.Item>
                  </div>
                  <div>
                    <Para style={{ justifyContent: "end", marginLeft: "1rem" }}>
                      I’m looking for a partner to help{" "}
                    </Para>
                  </div>
                </div>
              </Col>
            </Row>
            {/* third field */}

            <Row justify={"center"}>
              <Col span={24}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div>
                    <Para style={{ marginRight: "1rem" }}>me with</Para>
                  </div>
                  <div style={{ flex: 1 }}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input yourEmail Address",
                        },
                      ]}
                    >
                      <CustInput placeholder="My goal" />
                    </Form.Item>
                  </div>
                  <div>
                    <Para style={{ justifyContent: "end", marginLeft: "1rem" }}>
                      You can reach me at
                    </Para>
                  </div>
                </div>
              </Col>
            </Row>
            {/* forth */}

            <Row justify={"center"}>
              <Col span={24}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div>
                    <Para style={{ marginRight: "1rem" }}>me with</Para>
                  </div>
                  <div style={{ flex: 1 }}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input yourEmail Address",
                        },
                      ]}
                    >
                      <CustInput placeholder="My goal" />
                    </Form.Item>
                  </div>
                  <div>
                    <Para style={{ justifyContent: "end", marginLeft: "1rem" }}>
                      to start the conversation.
                    </Para>
                  </div>
                </div>
              </Col>
            </Row>

            {/* fifth */}
            <Row justify={"center"}>
              <Col span={24}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div>
                    <Para style={{ marginRight: "1rem" }}>
                      Optionally, I’m sharing more
                    </Para>
                  </div>
                  <div style={{ flex: 1 }}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input yourEmail Address",
                        },
                      ]}
                    >
                      <CustInputTextArea placeholder="Message" />
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>

            <Col span={24}>
              <Row justify={"end"}>
                <MWButton>Submit</MWButton>
              </Row>
            </Col>
          </Form>
        </Col>
      </Row>
      </Web>
      {/* //break point */}
      <Mobile>
      <Row justify={"center"}>
        <Col xs={22} sm={22} md={16} lg={12} xl={12} xxl={12}>
          <Form form={form} name="basic" onFinish={onFinish} autoComplete="off">
            <Row>
                <Col span={24}>
                <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input yourEmail Address",
                        },
                      ]}
                    >
                      <CustInput placeholder="My goal" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input yourEmail Address",
                        },
                      ]}
                    >
                      <CustInput placeholder="My goal" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input yourEmail Address",
                        },
                      ]}
                    >
                      <CustInput placeholder="My goal" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input yourEmail Address",
                        },
                      ]}
                    >
                      <CustInputTextArea placeholder="Message" />
                    </Form.Item>
                </Col>
            </Row>
            <Col span={24}>
              <Row justify={"end"}>
                <MWButton>Submit</MWButton>
              </Row>
            </Col>
            </Form>
            </Col>
            
            </Row>
      </Mobile>
    </>
  );
};

export default Opening;
