import React from "react";
import Slider from "react-slick";
import { Row, Col, Image, Button } from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import Navbar from "../Navbar";
import { Colors } from "../../common/theme/theme";

import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
const BoldHeading = styled.div`
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: white;
  font-family: "Teko", sans-serif;
`;
const SubHeading = styled.div`
  font-size: 18px;
  font-weight: 500;
  font-family: "Teko", sans-serif;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-inline: 0.5rem;
  color: white;
  @media (max-width: 519px) {
    width: 266px;
  }
`;

const FixedComponent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 90px;
  background-color: white;
  z-index: 9999;
  border-bottom: 1px solid #808080;
  margin: 0;
  padding: 0;
  @media (max-width: 992px) {
    border-bottom: 0px solid #808080;
  }
`;

const CustomRow = styled(Row)`
  height: 100vh;
  overflow: hidden;
  padding-bottom: 5vh;

`;
const SocialComponent = styled.div`
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 40px;

  background-color: transparent;
  z-index: 9999;
  border-bottom: 0px solid #808080;
  margin: 0;

  margin: 1rem;
  @media (max-width: 992px) {
    border-bottom: 0px solid #808080;
  }
`;
const StyledIcon = styled.span`
  font-size: 24px;
  transition: background-color 0.3s;
  border: 1px solid white;
  color: white;
  margin-top: 1rem;
  padding-top: 6px;
  padding-inline: 7px;
  border-radius: 50%;
  /* padding: 5px; */
  &:hover {
    background-color: white;
    color: ${Colors?.primary};
  }
`;
const CustomSlider = styled(Slider)`
  .custom-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    overflow-x: hidden;
  }
  .custom-dots .dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
  }
  .custom-dots .dot.slick-active {
    opacity: 1;
    background-color: red;
  }
  .slick-next {
    display: none;
  }
  .slide-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: #fff;
    text-align: center;
  }
`;
const StyledTitle = styled.span`
  @media (max-width: 824px) {
    font-size: 25px;
  }
  font-size: 46px;
`;
const StyledDesc = styled.span`
  @media (max-width: 657px) {
    font-size: 15px;
  }
  @media (max-width: 484px) {
    text-aligm: left;
  }
`;

const Slide = ({ src, caption, desc }) => {
  return (
    <div style={{ position: "relative" }}>
      <Image
        src={src}
        width={"100vw"}
        height={"75vh"}
        preview={false}
        style={{ filter: "brightness(.4)", objectfit: "cover" }}
      />

      <div className="slide-content">
        <BoldHeading>{caption}</BoldHeading>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "150px",
              border: "2px solid #01497C",
              marginBottom: "1rem",
            }}
          ></div>
        </div>
        <SubHeading>{desc}</SubHeading>
      </div>
    </div>
  );
};

const BannerComponent = ({ imagepath, title, desc }) => {
  const settings = {
    dots: true,
    dotsClass: "slick-dots custom-dots",
    customPaging: function (i) {
      return <div className="dot" />;
    },
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // change this to 1000 for 1 second interval
    nextArrow: null,
  };

  return (
    <div>
      <CustomRow>
        <FixedComponent>
          <Navbar />
        </FixedComponent>
        <Col span={24} style={{ marginTop: "90px" }}>
          <CustomSlider {...settings}>
            <Slide
              src={imagepath}
              caption={<StyledTitle>{title}</StyledTitle>}
              desc={<StyledDesc>{desc}</StyledDesc>}
            />
          </CustomSlider>
          <SocialComponent>
            <StyledIcon>
              <a
                href="https://www.facebook.com/Macworldtechnologies"
                target="_blank"
              >
                <FaFacebookF />
              </a>
            </StyledIcon>
            <StyledIcon>
              <a
                href="https://www.instagram.com/macworldtechnologies/"
                target="_blank"
              >
                <FaInstagram />
              </a>
            </StyledIcon>
            <StyledIcon>
              <a
                href="https://www.linkedin.com/company/mac-world-technologies/"
                target="_blank"
              >
                <FaLinkedinIn />
              </a>
            </StyledIcon>
            <StyledIcon>
              <FaTwitter />
            </StyledIcon>

            <StyledIcon>
              <FaYoutube />
            </StyledIcon>
          </SocialComponent>
        </Col>
      </CustomRow>
    </div>
  );
};

export default BannerComponent;
