import styled, { keyframes } from "styled-components";
import { Row, Col, Image, Grid, Card, Button, Modal, List, Input } from "antd";

import { Colors } from "../theme/theme";

import Bg from "../../assets/images/wordpressservicebg.png";
import ServiceBannerImg from "../../assets/images/Home/bb.png";
import LinkImg from "../../assets/images/Home/link.png";
import QuoteBg from "../../assets/images/QuoteBg.png";
// import Connectbg2 from "../../assets/images/webdesignservicebg"

export const BoldHeading = styled.div`
  text-align: left;
  font-size: 13px;
  font-weight: 900;
  color: #333;
  text-transform: uppercase;
  font-family: "Teko", sans-serif;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

export const SubHeading = styled.div`
  font-size: 18px;
  font-weight: 500;
  font-family: "Teko", sans-serif;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-inline: 0.5rem;
  color: #333;
`;

export const Title = styled.div`
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  color: #333;
  font-family: "Teko", sans-serif;
  border-bottom: 1px solid #333;
  margin-bottom: 2rem;
`;

export const SubTitle = styled.div`
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  font-family: "Teko", sans-serif;
`;

export const SubTitleLeft = styled.div`
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-family: "Teko", sans-serif;
  display: inline-block;
  padding: 1rem;
  border-radius: 20px;
  padding-right: 5rem;
  padding-left: 2rem;
`;

export const MiniMenu = styled.div`
  text-align: left;
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-family: "Teko", sans-serif;
  display: inline-block;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

  &:hover {
    font-weight: 600;
    background-color: ${Colors?.primary};
    padding: 0.5rem;
    border-radius: 5px;
    color: white;
  }
`;

export const StyledLinkContainer = styled.div`
  padding-inline: 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.bg};
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: transparent;
    transition: background-color 0.3s ease;
  }

  &:hover:after {
    background-color: ${Colors?.primary};
  }
`;

//Carousale

export const FixedComponent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 90px;
  background-color: transparent;
  z-index: 9999;
  border-bottom: 0px solid #808080;
  margin: 0;
  padding: 0;
  @media (max-width: 992px) {
    border-bottom: 0px solid #808080;
  }
`;
export const SocialComponent = styled.div`
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 40px;
  gap: 2;
  background-color: transparent;
  z-index: 9999;
  border-bottom: 0px solid #808080;
  margin: 0;

  margin: 1rem;
  @media (max-width: 992px) {
    border-bottom: 0px solid #808080;
  }
`;

export const StyledIcon = styled.span`
  font-size: 24px;
  transition: background-color 0.3s;
  border: 1px solid white;
  color: white;
  margin-top: 1rem;
  padding-top: 6px;
  padding-inline: 7px;
  border-radius: 50%;
  /* padding: 5px; */
  &:hover {
    background-color: white;
    color: ${Colors?.primary};
  }
`;

///Latest Services
export const Wrapper = styled.div`
  padding-top: 5vh;
  background-image: url(${ServiceBannerImg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 100px;
  background-position-x: -200px;

  @media (max-width: 1880px) {
    background-position-y: 150px;

    background-size: 900px 1000px;
  }
  @media (max-width: 1630px) {
    background-position-y: 150px;
    background-position-x: -180px;

    background-size: 800px 1000px;
  }
  @media (max-width: 1451px) {
    background-position-y: 150px;
    background-position-x: -180px;

    background-size: 750px 850px;
  }
  @media (max-width: 1310px) {
    background-position-y: 180px;
    background-position-x: -180px;

    background-size: 750px 850px;
  }
  @media (max-width: 1100px) {
    background-size: 950px 900px;
  }
`;

export const moveUpAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
`;

export const CardHeading = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-family: "Teko", sans-serif;
  margin-top: 2rem;
  color: ${Colors?.primary};
`;

export const CardPara = styled.div`
  font-size: 18px;
  font-weight: 500;
  font-family: "Teko", sans-serif;
  margin-top: 2rem;
  color: #333;
`;

export const StyledImage = styled.div`
  width: 32px;
  height: 32px;
  background-image: url(${LinkImg});
`;

export const LinkBox = styled.div`
  background-color: #3c4043;

  padding: 5px;

  border-radius: 20px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
`;

export const StyledCol = styled(Col)`
  border: 0.5px solid #e8e8e8;
  margin-inline: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  transition: background-color 0.3s;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  &:hover {
    animation: ${moveUpAnimation} 0.3s forwards;
    background-color: #3c4043;
    cursor: pointer;

    ${CardHeading} {
      color: white;
    }
    ${CardPara} {
      color: white;
    }
    ${StyledImage} {
      filter: grayscale(100%) brightness(20%);
    }

    ${LinkBox} {
      background-color: white;
    }
  }
`;

//Boost Your Business

export const QuoteDesc = styled.p`
  font-size: 18px;
  font-family: "Teko", sans-serif;
  font-weight: 600;
  text-align: start;
  margin-bottom: 0px;
  text-align: center;
  color: white;
`;

export const StyledRow = styled(Row)`
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
`;

//Explore

export const TransparentCard = styled(Card)`
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 20px;
  text-align: center;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-inline: 0.15rem;
  transition: background-color 0.3s ease;
  background-color: rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

export const SubHeadinggg = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-family: "Teko", sans-serif;
  margin-bottom: 12rem;
  padding-inline: 0.5rem;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 50%);
  text-align: center;
  width: 100%;
  transition: transform 0.3s ease;
  color: white;
  text-transform: uppercase;

  ${TransparentCard}:hover & {
    transform: translate(-50%, -400%);

    @media (max-width: 992px) {
      transform: translate(-50%, -500%);
    }
    @media (max-width: 810px) {
      transform: translate(-50%, -550%);
    }
    @media (max-width: 576px) {
      transform: translate(-50%, -400%);
    }
  }
`;

export const Container = styled.div`
  position: relative;
  background-image: ${(props) => props.bgImg};
  background-size: cover;
  background-position: center;
  width: 100%;

  height: 70vh;
  margin-top: 2rem;
  @media (max-width: 768px) {
    margin-bottom: 0vh;
  }
`;

export const StyledRowApp = styled(Row)`
  margin-top: 0;
`;
export const Content = styled.div`
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.3s ease, transform 0.3s ease;

  ${TransparentCard}:hover & {
    opacity: 1;
    transform: translateY(0);
  }
`;
export const AdditionalContent = styled.div`
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.3s ease, transform 0.3s ease;
  color: white;
  font-size: 16px;
  ${TransparentCard}:hover & {
    opacity: 1;
    transform: translateY(0);
  }
`;
//Choose Us banner

export const StyledChooseUsBanner = styled(Row)`
  background-image: url("${(props) => props.bgImg}");
  height: 100vh;
  margin-top: 20px;
  margin-bottom: 20px;
  // padding:40px;
  width: 100%;
  background-size: 70vw 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 425px) {
    width: 100vw;
    height: 250px;
    background-size: cover;
  }
  // @media (min-width: 791px) and (max-width:1326px){
  //   margin-top: 34rem;
  // }
`;

//Get Quote

export const BoldHeadingQuote = styled.div`
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  color: white;
  font-family: "Teko", sans-serif;
`;

export const MWButton = styled(Button)`
  background-color: #01497c;
  color: white;
  font-family: "Teko", sans-serif !important;

  width: 200px;
  font-size: 16px;
  font-weight: bold;
  opacity: 1;
  border: 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 40px;

  margin-top: 2rem;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
    color: white !important;
    text-decoration: none;
  }
`;
export const Calendar = styled.div`
  @media and (max-width: 765px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 65vh;
    width: 80vw;

    .ant-calendar {
      height: 5vh !important;
    }
  }
`;
export const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-calendar {
    height: 5vh !important;
  }
`;
export const Web = styled.div`
  background-image: url(${QuoteBg});
  background-size: 100% 100%;
`;

//Process Methadology
export const WrapperProcess = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:2rem;
  position:relative;


  @media (max-width: 1024px) {
    height: 100%;
    width: 100%;
    padding: 40px 0px;

  
  }

`;
export const CardDescProcess = styled.p`
  margin: 10px 0 0;
  font-size: 14px;
  color: #777;
  line-height: 1.4;
`;

export const CardTitleProcess = styled.p`
  margin: 0;
  font-size: 22px;
  color: rgb(1, 73, 124);
  font-weight: 700;
`;
export const CardContentProcess = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  text-align: center;
  padding: 13px;
  box-sizing: border-box;
  background-color: #f2f2f2;
  transform: rotateX(-90deg);
  transform-origin: bottom;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`;
export const CardImgProcess = styled.img`
  width: 100%;
  object-fit: cover;
  height: 100%;
  background-color: transparent;
  fill: #333;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`;
export const CardProcess = styled.div`
  height: 350px;
  width: 260px;
  border-radius: 12px;
  border: 5px solid rgb(216, 216, 216);
  position: relative;
  background-color: rgba(235, 233, 233, 0.856);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  perspective: 1000px;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgb(1, 73, 124);
    cursor: pointer;
    box-shadow: -3px 7px 27px -6px rgb(1, 73, 124);
    border: 2px solid rgb(1, 73, 124);
  }
  &:hover ${CardImgProcess} {
    background-color: transparent;
    filter: blur(2px);
  }
  &:hover ${CardContentProcess} {
    transform: rotateX(0deg);
  }
  @media (max-width: 1024px) {
    height: 350px;

 
  }
`;

//Discover
export const MWButtonTwo = styled(Button)`
  && {
    background-color: transparent;
    border: 0;
    color: black;
    height: 40px;
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-inline: 3rem;
    border: 2px solid black;
    &:hover {
      transform: translateY(-10px);
      border: 2px solid ${Colors?.primary};
      color: ${Colors?.primary};
    }
  }
`;
export const StyledImage1 = styled(Image)`
  width: 450px;
  border-radius: 20px;
  height: 660px;
  box-shadow: 8px 11px 10px -3px rgba(0, 0, 0, 0.75);
  object-fit: contain;
  @media (max-width: 992px) {
    margin-bottom: 2rem;
    width: 100vw;
    object-fit: contain;
  }
`;
export const WrapperDis = styled.div`
  position: relative;
`;

export const OptionsList = styled(List)`
  overflow-y: auto;
  border: 0px solid white;
  transition: all 0.3s ease;
`;
export const OptionsListItem = styled(List.Item)`
  border: 0px solid white;
  color: white !important;
  font-size: 18px;
`;
export const StyledImageLogo = styled(Image)`
  width: 48;
  height: 48;
  margin-bottom: 2rem;
  background-color: #d1d0d0;
  border-radius: 30px;
`;

//Ecommerce Development
export const StyleMetaDes = styled.div`
  text-align: left;
  font-family: "Teko", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #333;
`;
export const StyleMeta = styled.div`
  text-align: left;
  font-family: "Teko", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #333;
`;
export const StyleCard = styled(Card)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);

    ${StyledImageLogo} {
      // background-color: #01497c;
    }
  }
`;

//Contact Form
export const CustInput = styled(Input)`
  height: 55px;
  border: 0px solid #939393;
  width: 100%;
  background-color: #3c4043;
  border-radius: 0;
  width: 100%;
  color: white;
  &::placeholder {
    color: white;
  }
`;

//Magentobenifits
export const Wrapper1 = styled.section`
  height: 80vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    height: 100%;
    padding: 40px 0px;
  }
`;

//Contact Extraa

export const Extra = styled.div`
  background-image: url(${Bg});
  background-size: 100% 100%;
  height: auto;
  width: 100%;
  margin-top: 5vh;
  margin-bottom: 5vh;
  display: flex;
`;

export const LeftCorner = styled.div`
  padding-top: 15vh;
  align-items: left;
  width: 30%;
`;

export const RightCorner = styled.div`
  width: 70%;
  padding-top: 15vh;
  align-itmes: right;
  padding-left: 20vw;
`;

export const Buttonn = styled.button`
  @media (max-width: 425px) {
    margin-left: -1px;
  }
`;

//Testimonials

export const TestimonialsContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 30px 70px 30px;
  width: 100vw;



 
 
  @media (max-width: 768px) {
    padding: 40px 0px;
  
  }
  @media  (max-width: 1240px) {
    margin-top:50px
  }

  @media (min-width: 792px) and (max-width: 1326px) {

    padding-bottom: 50px;
  }

  @media (min-width: 769px) and (max-width: 791px) {
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-top: 3rem;
  margin-bottom: 10rem;
  & p {
    margin-top: 14px;
    color: #01497c;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 30px;
    padding-right: 30px;
  }
  & h1 {
    color: #ffbf20;
    text-align: center;
    font-size: 58px;
    font-weight: 600;
    color: #333;
    font-family: "Teko", sans-serif;
    line-height: normal;
    @media (max-width: 450px) {
      font-size: 48px;
    }
  }
`;

export const Line = styled.div`
  width: 100px;
  height: 2px;
  background-color: #01497c;
  border-radius: 12px;
`;

export const TestBox = styled.div`
  position: relative;
  width: 360px;
  height: 300px;
  flex-shrink: 0;
  border-radius: 8px 0px;
  background: #d9d9d9;
  display: flex;
  justify-content: center;
  gap: 40px;
  align-items: center;
  padding: 30px;
  flex-direction: column;
  & h1 {
    color: #01497c;
    letter-spacing: 1.2px;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 2rem;
  }
  & p {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  @media (max-width: 768px) {
    width: 80%;
    margin-top: 80px;
    margin-bottom: 20px;
  }

  @media (min-width: 1024px) and (max-width: 1363px) {
    height: 400px;
    width: 290px;
  }
  @media (min-width: 880px) and (max-width: 1023px) {
    hieght: 275px;
    width: 250px;
    margin-right: 10px;
  }
  @media (min-width: 769px) and (max-width: 879px) {
    hieght: 323px;
    width: 245px;
    margin-right: 21px;
    margin-left: -12px;
  }
`;

export const TestContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  width: 80%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  @media (min-width: 1024px) {
    width: 90%;
    margin-top: 40px;
  }
`;

export const ClosingQutation = styled.span`
  color: #01497c;
  font-family: Inter;
  font-size: 42px;
  font-weight: 400;
  max-height: 20px;
  margin-left: 4px;
  position: absolute;
`;

export const OpeningQutation = styled.span`
  margin-left: -20px;
  margin-top: -13px;
  position: absolute;
  color: #01497c;
  text-align: center;
  font-family: Inter;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const TestPic = styled.img`
  margin-top: -120px;
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 100%;
  border: 7px solid white @media (max-width: 768px) {
    margin-top: -80px;
  }
`;
