import React from 'react';
import styled from 'styled-components';
import { Row, Col, Modal } from 'antd';
import WhyChooseUs from '../../../assets/images/Digital Marketing/Why Choose Us/Why CHoose Us-01.png';
import { StyledChooseUsBanner } from '../../../common/GlobalStyles/global';

const ChooseUsBanner = () => {

  return (
    <Col xs={24}>
      <StyledChooseUsBanner bgImg={WhyChooseUs}>
      </StyledChooseUsBanner>
    </Col>
  );
};

export default ChooseUsBanner;
