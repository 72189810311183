import black_circle from "../../assets/images/Form/blackdot.png";
import blue_circle from "../../assets/images/Form/bluedot.png";
import brown_circle from "../../assets/images/Form/browndot.png";
import green_circle from "../../assets/images/Form/greendot.png";
import purple_circle from "../../assets/images/Form/purpledot.png";
import red_circle from "../../assets/images/Form/reddot.png";
import yellow_circle from "../../assets/images/Form/yellowdot.png";

import emailjs from "emailjs-com";
import Navbar from "../../common/Navbar";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Upload,
  message,
  Space,
  Input,
  Form,
  Image,
  notification,
  Row,
} from "antd";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100vw;
  height: content-fit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  && .ant-input {
    font-size: 1.15rem;
    font-weight: bold;
    padding: 2rem;
  }
`;
const Heading = styled.div`
  padding-bottom: 40px;

  text-align: left;
  font-size: 40px;
  font-weight: 600;
  color: #333;
  font-family: "Teko", sans-serif;
  margin-top: 10rem;
`;
const SubHeading = styled.div`
  font-size: 18px;
  font-weight: 500;
  font-family: "Teko", sans-serif;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-inline: 0.5rem;
  color: #333;
`;
const Div0 = styled.div`
  margin-bottom: 10rem;
  position: absolute;
  top: 0;
  z-index: 2;
  width: 100%;
`;

const HiringForm = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const title = searchParams.get("title");

  const phoneNumberRegex = /^[0-9]{11}$/;
  const expNumberRegex = /^(?:[0-9]|[1-4][0-9]|50)$/;
  const [form] = Form.useForm();

  const SendEmail = (values) => {
    var service_id = "service_h4osbvz";
    var template = "template_kx4978i";
    var public_key = "KbIr_yseQWektuBiB";
    var params = {
      firstname: values.firstname,
      lastname: values.lastname,
      linkedIn: values.linkedIn,
      applied: title,
      education: values.Education,
      experience: values.Experience,
      email: values.email,
      phone: values.phone,
    };
    emailjs.send(service_id, template, params, public_key).then(function (res) {
      notification.success({
        message: "Form Submitted",
        description: "Your form has been submitted successfully!",
      });

      form.resetFields();
    });
  };

  const onFinishFailed = (errorInfo) => {
    const missingFields = errorInfo.errorFields.map((field) => field.name[0]);
    notification.error({
      message: "Form Submission Failed",
      description: `The following fields are missing: ${missingFields.join(
        ", "
      )}.`,
    });
  };

  const onFinish = (values) => {
    console.log("Values ", values);
    SendEmail(values);
  };

  return (
    <Wrapper>
      <Div0>
        <Navbar />
      </Div0>
      <Heading>Personal Information</Heading>
      <SubHeading>
        Applying For <span style={{ color: "red" }}>{title}</span>{" "}
      </SubHeading>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        scrollToFirstError
      >
        <Space direction="vertical" size={30}>
          <Row>
            <Form.Item
              name="firstname"
              label=""
              rules={[
                {
                  required: true,
                  message: "Please Enter Your First Name",
                },
                {
                  min: 2,
                  message: "First Name Should be More than 1 word",
                },
                {
                  max: 20,
                  message: "First Name Should be Less than 8 word",
                },
              ]}
              hasFeedback
            >
              <Input
                prefix={
                  <Image
                    src={red_circle}
                    style={{ width: "20px", height: "20px" }}
                  />
                }
                placeholder="Enter Your First Name "
                style={{
                  width: "36vw",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  boxShadow: "none",
                  borderRadius: "0",
                  marginRight: "3vw",
                }}
              />
            </Form.Item>

            <Form.Item
              name="lastname"
              label=""
              rules={[
                {
                  required: true,
                  message: "Please Enter Your Last Name",
                },
                {
                  min: 2,
                  message: "Last Name Should be More than 1 word",
                },
                {
                  max: 20,
                  message: "Last Name Should be Less than 8 word",
                },
              ]}
              hasFeedback
            >
              <Input
                prefix={
                  <Image
                    src={black_circle}
                    style={{ width: "20px", height: "20px" }}
                  />
                }
                placeholder="Enter Your Last Name "
                style={{
                  width: "36vw",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  boxShadow: "none",
                  borderRadius: "0",
                }}
              />
            </Form.Item>
          </Row>

          <Form.Item
            name="email"
            label=""
            rules={[
              {
                required: true,
                message: "Please Enter Your Email Address",
              },
              {
                type: "email",
                message: "Not Valid Email Address",
              },
            ]}
            hasFeedback
          >
            <Input
              prefix={
                <Image
                  src={red_circle}
                  style={{ width: "20px", height: "20px" }}
                />
              }
              placeholder="Enter Your Email Address "
              style={{
                width: "75vw",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                boxShadow: "none",
                borderRadius: "0",
              }}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            label=""
            rules={[
              {
                required: true,
                message: "Please Enter Phone Number",
              },
              {
                pattern: phoneNumberRegex,
                message: "Number Should Have 11 digits",
              },
              {},
            ]}
            hasFeedback
          >
            <Input
              prefix={
                <Image
                  src={yellow_circle}
                  style={{ width: "20px", height: "20px" }}
                />
              }
              placeholder="Enter Your Phone Number "
              style={{
                width: "75vw",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                boxShadow: "none",
                borderRadius: "0",
              }}
            />
          </Form.Item>
          <Form.Item
            name="LinkedIn"
            label=""
            rules={[
              {
                type: "url",
                message: "Please Provide Valid LinkedIn Adress",
              },
            ]}
            hasFeedback
          >
            <Input
              prefix={
                <Image
                  src={blue_circle}
                  style={{ width: "20px", height: "20px" }}
                />
              }
              placeholder="Enter Your LinkedIn Address "
              style={{
                width: "75vw",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                boxShadow: "none",
                borderRadius: "0",
              }}
            />
          </Form.Item>
          <Form.Item
            name="Experience"
            label=""
            rules={[
              {
                required: "true",
                message: "Please Provide Your Experience   ",
              },
              {
                pattern: expNumberRegex,
                message: "Not Valid",
              },
            ]}
            hasFeedback
          >
            <Input
              prefix={
                <Image
                  src={green_circle}
                  style={{ width: "20px", height: "20px" }}
                />
              }
              placeholder="Experience"
              style={{
                width: "75vw",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                boxShadow: "none",
                borderRadius: "0",
              }}
            />
          </Form.Item>
          <Form.Item
            name="Education"
            label=""
            rules={[
              {
                required: "true",
                message: "Please Provide Your Education  ",
              },
            ]}
            hasFeedback
          >
            <Input
              prefix={
                <Image
                  src={purple_circle}
                  style={{ width: "20px", height: "20px" }}
                />
              }
              placeholder="Education"
              style={{
                width: "75vw",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                boxShadow: "none",
                borderRadius: "0",
              }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              style={{ width: "25vw", borderRadius: "15px" }}
            >
              Submit
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default HiringForm;
