import React from "react";
import GetQuote from "../../components/HomeScreen/GetQuote";
import Carousel from "../../components/HomeScreen/Carousel";
import BoostYourBusiness from "../../components/HomeScreen/BoostYourBusiness";
import Discover from "../../components/HomeScreen/Discover";
import Explore from "../../components/HomeScreen/Explore";
import LatestService from "../../components/HomeScreen/LatestService";
import TeamExpertise from "../../components/HomeScreen/TeamExpertise";
import ProcessMethadology from "../../components/HomeScreen/ProcessMethadology";
import ChooseUsBanner from "../../components/HomeScreen/ChooseUsBanner";


import Img1 from "../../assets/images/Home/Initial Consultation.png"
import Img2 from "../../assets/images/Home/planning.png"
import Img4 from "../../assets/images/Home/customer support.png"
import Img3 from "../../assets/images/Home/implemetation.jpg"
import {Helmet} from 'react-helmet'
import HeroText from "../../components/HomeScreen/HeroText";

const Home = () => {
  
  return (
    <>
	  <Helmet>
	  <meta charSet="utf-8" />
                <title>  

Trusted IT Solution Company | IT Services In Dubai, UAE 
	  </title>
	  <meta id="meta-description" name="description" content="Mac world Technology is a trusted IT solution company in Dubai, UAE, providing comprehensive IT services tailored to meet your business needs. Contact us for reliable and innovative IT solutions to drive your organization's success." />
                <link rel="canonical" href="https://macworldtechnology.com/home" />
	  </Helmet>
      <Carousel />
      <HeroText title={"Trusted IT Solution Company | IT Services In Dubai, UAE "} description={"Mac world Technology is a trusted IT solution company in Dubai, UAE, providing comprehensive IT services tailored to meet your business needs. Contact us for reliable and innovative IT solutions to drive your organization's success."}></HeroText>
      <LatestService />
      <BoostYourBusiness />
      <Explore />
      <ChooseUsBanner/>
      <GetQuote title="Get A Consultaion For Free" description="Contact Mac World Technology right away for further details, a free consultation, or a unique IT solution. Our knowledgeable team is prepared to help you realize your business objectives by utilizing cutting-edge IT solutions. Reach out to us right away to leverage technology for the growth of your company." />
      <ProcessMethadology
      title="Process or Methodology"
      description="At Mac World Technology, we provide IT solutions to our clients in a methodical and tested manner. Our methodology guarantees a successful project's lifetime and execution. Our procedure entails"
       CardData={[
      {title:"Initial Consultation",img:Img1,desc:"Through in-depth consultation sessions, we start by learning about the particular requirements and goals of our clients."},
      {title:"Analysis and Planning:",img:Img2,desc:"To find the best IT solutions for our clients, our team does a thorough study and develops a detailed strategy."},
      {title:"Implementation",img:Img3,desc:"After the plan is complete, we carry out the suggested fixes to make sure current systems are seamlessly integrated."},
      {title:"Ongoing Support",desc:"We give our clients ongoing support to make sure their IT systems are always operational and up and running. ",img:Img4},
    ]}/>
      <TeamExpertise/>
      <Discover />
    </>
  );  
};
export default Home;
