import React,{useState} from 'react';
import {  Col} from 'antd';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import broucherBanner from '../../../assets/images/Home/IT.jpg';
import keysectorlogistics from '../../../assets/images/Home/keysectorlogistics.jpg'
import keysectormedical from '../../../assets/images/Home/Medical.jpg'
import keysectoreducation from '../../../assets/images/Home/Education.jpg'
import keysectorefood from '../../../assets/images/Home/Food.jpg'
import keysectorerealestate from '../../../assets/images/Home/realestate.jpg'

import { BoldHeading } from '../../../common/GlobalStyles/global';
import { SubHeading } from '../../../common/GlobalStyles/global';
import { SubHeadinggg } from '../../../common/GlobalStyles/global';
import { TransparentCard } from '../../../common/GlobalStyles/global';
import { Container } from '../../../common/GlobalStyles/global';
import { StyledRowApp } from '../../../common/GlobalStyles/global';
import { Content } from '../../../common/GlobalStyles/global';
import { AdditionalContent } from '../../../common/GlobalStyles/global';













const App = () => {
  const [hoveredCardIndex, setHoveredCardIndex] = useState(1);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    cssEase: 'linear',
    arrows: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
   <>
   <StyledRowApp justify={"center"} >
            <Col span={22}>
              <BoldHeading style={{fontSize:"2.5rem" , fontWeight:"600"}}>Industries Served</BoldHeading>
            </Col>
            <Col span={22}>
              <SubHeading>We specialize in delivering services to various sectors since we are familiar with their unique needs. Here are a few of the sectors we work with:</SubHeading>
            </Col>
          </StyledRowApp>
          <Container bgImg={hoveredCardIndex === 1 ? `url(${broucherBanner})` :
           hoveredCardIndex ===2 ?`url(${keysectormedical})`:hoveredCardIndex ===3 
           ? `url(${keysectorlogistics})`:hoveredCardIndex===4 ?`url(${keysectoreducation})`:
           hoveredCardIndex===5 ?`url(${keysectorefood})`:hoveredCardIndex===6 ?`url(${keysectorerealestate})`:
           `url(${broucherBanner})`}>
              
      <Slider {...settings}>
        <div>
          <TransparentCard
           onMouseEnter={() => setHoveredCardIndex(1)}
           onMouseLeave={() => setHoveredCardIndex(1)}
          >
            <SubHeadinggg>Information Techhnology</SubHeadinggg>
            <Content>
              <AdditionalContent>
                We have been working hard to make user experience awesome because we are not happy until you are.
              </AdditionalContent>
            </Content>
          </TransparentCard>
        </div>
        <div>
        <TransparentCard
           onMouseEnter={() => setHoveredCardIndex(2)}
           onMouseLeave={() => setHoveredCardIndex(1)}
          >
            <SubHeadinggg>Healthcare</SubHeadinggg>
            <Content>
              <AdditionalContent>
              To enable efficient communication, data administration, and patient care, the healthcare industry needs reliable and secure IT solutions. Our industry knowledge guarantees trustworthy and legal IT systems.
              </AdditionalContent>
            </Content>
          </TransparentCard>
        </div>
        <div>
          <TransparentCard
           onMouseEnter={() => setHoveredCardIndex(3)}
           onMouseLeave={() => setHoveredCardIndex(1)}
          >
            <SubHeadinggg>Hospitality</SubHeadinggg>
            <Content>
              <AdditionalContent>
              Our solutions aid in streamlining operations, enhancing guest experiences, and increasing overall efficiency because technology is essential to the success of the hospitality sector.
              </AdditionalContent>
            </Content>
          </TransparentCard>
        </div>
        <div>
          <TransparentCard
            onMouseEnter={() => setHoveredCardIndex(4)}
            onMouseLeave={() => setHoveredCardIndex(1)}
            >
            <SubHeadinggg>Education</SubHeadinggg>
            <Content>
              <AdditionalContent>
              We offer cutting-edge IT solutions to educational institutions that promote fluid communication, group learning, and effective resource management.
              </AdditionalContent>
            </Content>
          </TransparentCard>
        </div>
        <div>
          <TransparentCard
            onMouseEnter={() => setHoveredCardIndex(5)}
            onMouseLeave={() => setHoveredCardIndex(1)}
            >
            <SubHeadinggg>Finance</SubHeadinggg>
            <Content>
              <AdditionalContent>
              Using our modern financial technology solutions, we help businesses increase security, optimize financial processes, and maintain regulatory compliance.
              </AdditionalContent>
            </Content>
          </TransparentCard>
        </div>
        <div>
          <TransparentCard
            onMouseEnter={() => setHoveredCardIndex(6)}
            onMouseLeave={() => setHoveredCardIndex(1)}
            >
          
            <SubHeadinggg>Retail</SubHeadinggg>
            <Content>
              <AdditionalContent>
              To satisfy the changing needs of clients, the retail business needs IT solutions that are adaptable and scalable. Our customized solutions improve inventory control, internet visibility, and consumer experiences.
              </AdditionalContent>
            </Content>
          </TransparentCard>
        </div>
      </Slider>
    </Container>
    </>
  );
};

export default App;
